import React from 'react';
import PropTypes from 'prop-types';
import { CurrencySymbol } from '../../../../../util/formatter';

function PriceCellEditable({
  currency,
  skuPrice,
  skuDraftPrice,
  game,
  showDraft,
  onChange,
  saving,
  locked,
  onCursor,
  className,
}) {
  const [isDraft, setIsDraft] = React.useState(false);
  React.useEffect(() => {
    if (skuDraftPrice && showDraft) {
      setIsDraft(true);
    } else {
      setIsDraft(false);
    }
  }, [game.isDraft, skuDraftPrice, showDraft]);

  const [price, setPrice] = React.useState('');
  const [edited, setEdited] = React.useState(false);

  React.useEffect(() => {
    if (isDraft) {
      setPrice(skuDraftPrice || skuPrice);
    } else {
      setPrice(skuPrice);
    }
  }, [skuDraftPrice, skuPrice, isDraft]);

  const localOnChange = (value, game) => {
    setPrice(value);
    setEdited(true);
  };

  const localOnBlur = (game) => {
    if (edited === false) {
      return;
    }

    if (skuDraftPrice === parseFloat(price)) {
      return;
    }

    // single change
    const newPrice = parseFloat(price);
    if (isNaN(newPrice)) {
      setPrice(skuDraftPrice || skuPrice);
      return;
    }
    onChange(game, currency, newPrice);
  };

  return (
    <div
      className={
        'grid-empty full-cell grid-price p-0 num sku sku-input' +
        (isDraft ? ' draft' : '') +
        (saving ? ' saving' : '')
      }
    >
      {CurrencySymbol(currency.culture, currency.code.split('_')[0], 'ms-2')}
      <input
        type="text"
        value={price}
        readOnly={locked || saving ? 'readOnly' : ''}
        onBlur={() => {
          localOnBlur(game);
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === 'Tab') {
            localOnBlur(game);
          }
          // if it's an arrow key then call onCursor
          if (onCursor) {
            if (
              e.key === 'ArrowUp' ||
              e.key === 'ArrowDown' ||
              e.key === 'ArrowLeft' ||
              e.key === 'ArrowRight'
            ) {
              onCursor(e.key);
            }
          }
        }}
        placeholder={!game.isSKU ? currency.code : '0.00'}
        className={`text-right form-control sku num ${className}`}
        onChange={(e) => localOnChange(e.target.value, game)}
      ></input>
    </div>
  );
}

PriceCellEditable.propTypes = {
  currency: PropTypes.object.isRequired,
  skuPrice: PropTypes.number,
  skuDraftPrice: PropTypes.number,
  game: PropTypes.object.isRequired,
  showDraft: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  saving: PropTypes.bool,
};

export default PriceCellEditable;
