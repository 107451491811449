import { t } from 'i18next';
import React from 'react';

const NoProductCode = () => {
  return (
    <span className="flex justify-content-center" title={t('No Product Code')}>
      -
    </span>
  );
};
export default NoProductCode;
