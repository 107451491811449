import React from 'react';
import { NavLink } from 'react-router-dom';

function SKUNameCell({ promotion, sku, editable }) {
  return (
    <div className="grid-sku-name">
      <NavLink to={`/sku/${sku.skuId}`}>{sku.name}</NavLink>
    </div>
  );
}

export default SKUNameCell;
