import React from 'react';
import PropTypes from 'prop-types';
import ImageWithFallback from '../../common/ImageWithFallback';
import icons from '../../../constants/icons';
import FormStatic from '../../common/FormStatic';
import * as DOMPurify from 'dompurify';
import { Sparklines, SparklinesCurve } from 'react-sparklines';
import CompanyLink from '../../common/links/CompanyLink';
import CollapsePanel from '../../common/CollapsePanel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AutoSelectText from '../../common/AutoSelectText';
import { useTranslation } from 'react-i18next';
import NoAppId from '../../common/NoAppId';

const GameDetailsAdvanced = ({ game }) => {
  const { t } = useTranslation();
  const muhahaha = (e) => {
    const audio = new Audio('/content/sound/muhahaha.mp3');
    audio.play();
  };

  return (
    <CollapsePanel
      storageKey={'gameDetailsAdvanced'}
      header={
        <>
          <div className="game-img-wrapper me-4">
            <ImageWithFallback
              url={game.packshotUrl}
              fallBackImage={
                <div className="game-img">
                  <div className="icon-wrapper">
                    <FontAwesomeIcon icon={icons.GAME} className={`game`} />
                  </div>
                </div>
              }
              alt={game.name}
            ></ImageWithFallback>
          </div>
          <h2 className="no-margin">{game.name || t('Game')}</h2>
          <div
            className="ms-4"
            style={{
              height: 35,
              width: 100,
            }}
            title={t('Request activity last 90 days')}
          >
            <Sparklines
              data={[
                game.requestActivityScore30to60Days,
                game.requestActivityScore7to30Days,
                game.requestActivityScoreLast7Days,
              ]}
              min={0}
              max={100}
              height={35}
              width={100}
              style={{
                opacity:
                  game.requestActivityScore30to60Days +
                    game.requestActivityScore7to30Days +
                    game.requestActivityScoreLast7Days >
                  0
                    ? 1
                    : 0.5,
              }}
            >
              <SparklinesCurve color="#46b29d" />
            </Sparklines>
          </div>
          <div
            className="ms-4"
            style={{
              height: 35,
              width: 100,
            }}
            title={t('Codes requested last 90 days')}
          >
            <Sparklines
              data={[
                game.keyActivityScore30to60Days,
                game.keyActivityScore7to30Days,
                game.keyActivityScoreLast7Days,
              ]}
              min={0}
              max={100}
              height={35}
              width={100}
              style={{
                opacity:
                  game.keyActivityScore30to60Days +
                    game.keyActivityScore7to30Days +
                    game.keyActivityScoreLast7Days >
                  0
                    ? 1
                    : 0.5,
              }}
            >
              <SparklinesCurve color="#bb5ebf" />
            </Sparklines>
          </div>
          <div className="flex-1 text-right">
            {game.isArchived && (
              <h1 className="archived no-margin">
                <FontAwesomeIcon
                  icon={icons.ARCHIVED}
                  style={{ cursor: 'pointer' }}
                  onClick={muhahaha}
                />{' '}
                {t('Archived')}
              </h1>
            )}
            {!game.isArchived && game.isLicenseExpired && (
              <h1 className="c-red no-margin">
                <FontAwesomeIcon icon={icons.GAME_LICENSE_EXPIRED} />
                {t('License expired')}
              </h1>
            )}
          </div>
        </>
      }
    >
      <>
        <div className="col-count-2">
          <FormStatic label={t('App Id')}>
            {game.appId ? (
              <AutoSelectText>{game.appId}</AutoSelectText>
            ) : (
              <NoAppId />
            )}
          </FormStatic>
          <FormStatic label={t('License expiry date')}>
            {game.licenseExpires || '-'}
          </FormStatic>
          <FormStatic label={t('Publisher')}>
            <CompanyLink
              id={game.publisherCompanyId}
              name={game.publisherCompanyName}
            />
          </FormStatic>
          <FormStatic label={t('Developer')}>
            <CompanyLink
              id={game.developerCompanyId}
              name={game.developerCompanyName}
            />
          </FormStatic>
        </div>
        <div className="pb-2 col-12">
          <label className="col-form-label">{t('Notes')}</label>
          <div className="ps-0">
            <div
              className="form-control-static px-3 flex h-100 w-100 align-items-center"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(game.notesHTML || '-'),
              }}
            ></div>
          </div>
        </div>
      </>
    </CollapsePanel>
  );
};

GameDetailsAdvanced.propTypes = {
  game: PropTypes.object.isRequired,
};

export default GameDetailsAdvanced;
