import React from 'react';
import { useTranslation } from 'react-i18next';

function GameNameHeaderCell({ filterGame, handleFilterGame }) {
  const { t } = useTranslation();
  return (
    <div className="grid-header">
      {t('Game')}
      <br />
      <input
        type="text"
        className="form-control"
        placeholder={t('Filter')}
        value={filterGame || ''}
        onChange={handleFilterGame}
      />
    </div>
  );
}

export default GameNameHeaderCell;
