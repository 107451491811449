import React, { useMemo, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import KwPanel from '../common/KwPanel';
import { useDropzone } from 'react-dropzone';
import {
  uploadPrices,
  clearUploadResults,
} from '../../actions/skuPriceActions';
import Loading from '../common/Loading';
import './UploadPricing.css';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import image_xlsx_file_with_content from '../../content/images/csv-file-with-content.svg';
import image_upload_arrow from '../../content/images/upload-arrow.svg';
import ViewLink from './components/ViewLink';
import SuccessIcon from './components/SuccessIcon';

function UploadPricing({
  gameSKUPricingUploadResults,
  clearUploadResults,
  setClasses,
}) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [processingStarted, setProcessingStarted] = useState(false);
  useEffect(() => {
    setClasses('h-100');
  }, [setClasses]);

  const onDrop = (droppedFiles) => {
    clearUploadResults();
    setLoading(true);
    uploadPrices({ file: droppedFiles[0] })
      .then(() => {
        setLoading(false);
        toast.success(t('Price upload started'));
        setProcessingStarted(true);
      })
      .catch(() => {
        setLoading(false);
        toast.error(t('Failed to upload prices'));
      });
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop });
  const classeNames = useMemo(
    () =>
      `dropzone game-upload-action-panel ${isDragActive ? 'active' : ''} ${
        isDragAccept ? 'accept positive-colour1' : ''
      } ${isDragReject ? 'reject' : ''}`,
    [isDragActive, isDragReject, isDragAccept]
  );

  return (
    <>
      {!loading ? (
        <KwPanel className="mb-4">
          <div className="game-upload-wrapper">
            <div className="game-upload-action-panel game-upload-divider ps-lg-5 pe-lg-5">
              <img
                src={image_upload_arrow}
                alt={t('Arrow')}
                width="100"
                height="100"
                className="space-bottom"
              />
            </div>
            <div {...getRootProps({ className: classeNames })}>
              <input {...getInputProps()} />
              <img
                src={image_xlsx_file_with_content}
                alt={t('Upload your pricing')}
                className="space-bottom"
              />
              <p>{t('Drag & drop Steam price export here')}</p>
            </div>
          </div>
        </KwPanel>
      ) : (
        <Loading></Loading>
      )}

      {processingStarted && (
        <>
          <div className="progress">
            <div
              className="progress-bar progress-bar-striped progress-bar-animated"
              role="progressbar"
              aria-valuenow={gameSKUPricingUploadResults?.percentage ?? 0}
              aria-valuemin="0"
              aria-valuemax="100"
              style={{
                width: `${gameSKUPricingUploadResults?.percentage ?? 0}%`,
              }}
            ></div>
          </div>
          <KwPanel className="flex-1 overflow-auto">
            <table className="table dataTable width-100 ">
              <thead>
                <tr>
                  <td style={{ width: '5%' }}></td>
                  <td style={{ width: '5%' }}>{t('Row')}</td>
                  <td style={{ width: '25%' }}>{t('SKU')}</td>
                  <td style={{ width: '25%' }}>{t('Package Id')}</td>
                  <td style={{ width: '35%' }}>{t('Message')}</td>
                  <td style={{ width: '5%' }}></td>
                </tr>
              </thead>
              <tbody>
                {gameSKUPricingUploadResults?.updates?.map((result) => {
                  return (
                    <tr key={result.row === -1 ? result.skuId : result.row}>
                      <td>
                        <SuccessIcon result={result}></SuccessIcon>
                      </td>
                      <td>{result.row < 0 ? '' : result.row}</td>
                      <td>{result.packageName}</td>
                      <td>{result.packageId}</td>
                      <td>{result.message}</td>
                      <td>
                        <ViewLink result={result}></ViewLink>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </KwPanel>
        </>
      )}
    </>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    gameSKUPricingUploadResults: state.gameSKUPricingUploadResults,
  };
}

const mapDispatchToProps = { clearUploadResults };

export default connect(mapStateToProps, mapDispatchToProps)(UploadPricing);
