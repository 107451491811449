import React from 'react';
import NoAppId from '../../../../common/NoAppId';
import AutoSelectText from '../../../../common/AutoSelectText';

function GameAppIdCell({ game, editable }) {
  return (
    <div className="grid-game-name num text-center">
      {game.appId ? <AutoSelectText>{game.appId}</AutoSelectText> : <NoAppId />}
    </div>
  );
}

export default GameAppIdCell;
