import React from 'react';
import { NavLink } from 'react-router-dom';

function GameNameCell({ game, editable }) {
  return (
    <div className="grid-game-name">
      <NavLink to={`/game/${game.gameId}`} className="game-title">
        {game.name}
      </NavLink>
    </div>
  );
}

export default GameNameCell;
