import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import LeftNavWrapper from '../menu-components/LeftNavWrapper';
import LeftMenuItem from '../menu-components/LeftMenuItem';
import permissionTypes from '../../../constants/permissionTypes';
import userTypes from '../../../constants/userTypes';
import icons from '../../../constants/icons';
import NavColours from '../../../constants/NavColours';
import { useTranslation } from 'react-i18next';

function LeftMenu_Home({ currentUser, companyTypes }) {
  // default company type
  const [defaultCompanyType, setDefaultCompanyType] = useState();
  useEffect(() => {
    if (companyTypes) {
      setDefaultCompanyType(companyTypes.find((x) => x.isDefault));
    }
  }, [companyTypes, setDefaultCompanyType]);
  const { t } = useTranslation();

  return (
    <LeftNavWrapper>
      <LeftMenuItem
        url="/requests/all"
        icon={icons.REQUESTS}
        colour={NavColours.NAV_COLOUR_1}
        title={t('Requests')}
        currentUser={currentUser}
      />
      <LeftMenuItem
        url="/games"
        icon={icons.GAME}
        colour={NavColours.NAV_COLOUR_1}
        title={t('Games')}
        currentUser={currentUser}
        allowedUserTypes={[
          userTypes.REQUESTER,
          userTypes.UPLOADER,
          userTypes.APPROVER,
          userTypes.ADMIN,
        ]}
      />
      <LeftMenuItem
        url="/companies"
        icon={icons.COMPANY}
        colour={NavColours.NAV_COLOUR_1}
        title={t('Companies')}
        currentUser={currentUser}
        allowedPermissions={[permissionTypes.MANAGE_COMPANIES]}
      />
      <LeftMenuItem
        url="/keys"
        icon={icons.REQUEST_KEYS}
        colour={NavColours.NAV_COLOUR_1}
        title={t('Keys')}
        currentUser={currentUser}
        allowedUserTypes={[userTypes.REQUESTER]}
        allowedPermissions={[permissionTypes.MANAGE_KEYS]}
      />
      <LeftMenuItem
        url="/promotions"
        icon={icons.PROMOTION}
        colour={NavColours.NAV_COLOUR_1}
        title={t('Promotions')}
        currentUser={currentUser}
        allowedPermissions={[
          permissionTypes.MANAGE_PROMOTIONS,
          permissionTypes.VIEW_PROMOTIONS,
        ]}
      />
      <LeftMenuItem
        url="/pricing/all"
        icon={icons.PRICING}
        colour={NavColours.NAV_COLOUR_1}
        title={t('Pricing')}
        currentUser={currentUser}
        allowedPermissions={[
          permissionTypes.MANAGE_PRICING,
          permissionTypes.VIEW_PRICING,
        ]}
      />
      <LeftMenuItem
        url="/reports/requests-by-game"
        icon={icons.FILE}
        colour={NavColours.NAV_COLOUR_1}
        title={t('Reports')}
        currentUser={currentUser}
        allowedPermissions={[permissionTypes.VIEW_REPORTS]}
      />
      {defaultCompanyType ? (
        <LeftMenuItem
          url={`/users/?f_companyTypeId=${defaultCompanyType.id}`}
          icon={icons.USER}
          colour={NavColours.NAV_COLOUR_1}
          activeRegex="^\/user(s)?\/"
          title={t('Users')}
          allowedPermissions={[permissionTypes.MANAGE_USERS]}
          currentUser={currentUser}
        />
      ) : (
        <LeftMenuItem
          url="/users/"
          icon={icons.USER}
          colour={NavColours.NAV_COLOUR_1}
          activeRegex="^\/user(s)?\/"
          title={t('Users')}
          allowedPermissions={[permissionTypes.MANAGE_USERS]}
          currentUser={currentUser}
        />
      )}
      <LeftMenuItem
        url="/settings/workflows/"
        activeif="/settings/workflow"
        icon={icons.WORKFLOW}
        colour={NavColours.NAV_COLOUR_1}
        title={t('Workflows')}
        currentUser={currentUser}
        allowedPermissions={[permissionTypes.MANAGE_WORKFLOWS]}
      />
    </LeftNavWrapper>
  );
}

export default withRouter(LeftMenu_Home);
