import React from 'react';
import GameCurrencyNameCellEditable from './GameCurrencyNameCellEditable';

function GameCurrencyNameCell({
  game,
  currency,
  editable,
  onChange,
  locked,
  className,
  onCursor,
}) {
  return editable ? (
    <GameCurrencyNameCellEditable
      game={game}
      currency={currency}
      onChange={onChange}
      locked={locked}
      className={className}
      onCursor={onCursor}
    />
  ) : (
    <div className="grid-game-name text-right" title={currency.name}>
      {currency.code}
    </div>
  );
}

export default GameCurrencyNameCell;
