import { t } from 'i18next';
import React from 'react';

const NoAppId = () => {
  return (
    <span className="flex justify-content-center" title={t('No App Id')}>
      -
    </span>
  );
};
export default NoAppId;
