import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SlideoutLayout from '../../layout/slideout/SlideoutLayout';
import KwPanel from '../../common/KwPanel';

import { unarchiveGame } from '../../../actions/gameActions';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import FormToggleBool from '../../common/FormToggleBool';
import Button from '../../common/Button';
import ConfirmationHeader from '../../common/ConfirmationHeader';
import { useTranslation, Trans } from 'react-i18next';

function Unarchive({ done, id, cancel, unarchiveGame }) {
  const { handleSubmit, control } = useForm();
  const { t } = useTranslation();

  const handleUnarchive = (data) => {
    unarchiveGame(id, data.restorePendingRequests)
      .then(() => {
        toast.success(t('Game successfully unarchived'));
        done();
      })
      .catch(() => {
        toast.error(t('Failed to unarchive game'));
      });
  };

  return (
    <SlideoutLayout
      title={t('Unarchive confirmation')}
      cancel={cancel}
      done={done}
    >
      <KwPanel>
        <ConfirmationHeader text={t('Are you sure?')} />
        <p>
          {t(
            'Once the game is unarchived, users will be able to request keys again.'
          )}
        </p>
        <p>
          <Trans i18nKey="unarchiveConfirmation" components={{ bold: <b /> }}>
            You can also <bold>'Restore pending requests'</bold> from before the
            game was archived.
          </Trans>
        </p>
        <FormToggleBool
          control={control}
          name="restorePendingRequests"
          label={t('Restore pending requests?')}
          defaultValue={false}
        />
        <hr />
        <Button
          className="btn btn-primary me-2"
          onClick={handleSubmit(handleUnarchive)}
          text={t("Yes, unarchive the game and it's skus")}
        />
        <Button
          className="btn btn-default"
          onClick={cancel}
          autoFocus={true}
          text={t('No, cancel')}
        />
      </KwPanel>
    </SlideoutLayout>
  );
}
Unarchive.propTypes = {
  id: PropTypes.string,
  cancel: PropTypes.func.isRequired,
  done: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {};
}

const mapDispatchToProps = {
  unarchiveGame,
};

export default connect(mapStateToProps, mapDispatchToProps)(Unarchive);
