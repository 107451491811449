import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import SlideoutLayout from '../../../layout/slideout/SlideoutLayout';
import {
  copyMembers,
  getUserProfileGroups,
} from '../../../../actions/userProfileGroupActions';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import Button from '../../../common/Button';
import FormSelect from '../../../common/FormSelect';

function CopyMembers({ targetProfileGroupId, done, cancel }) {
  const { t } = useTranslation();
  const [userProfileGroups, setUserProfileGroups] = useState([]);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const onSubmit = (data) => {
    if (data.sourceProfileGroupId) {
      copyMembers(data.sourceProfileGroupId, targetProfileGroupId)
        .then((d) => {
          if (d.isSuccess) {
            toast.success(d.message || t('Copied members to group'));
            done();
          } else {
            toast.error(d.message || t('Failed to copy members to group'));
          }
        })
        .catch(() => {
          toast.error(t('Failed to copy members to group'));
        });
      return;
    }
    toast.error(t('Please fill the required fields'));
  };

  useEffect(() => {
    const loadGroups = async () => {
      const groups = await getUserProfileGroups();
      const filteredGroups = groups.data.filter(
        (group) => group.id !== targetProfileGroupId && group
      );
      setUserProfileGroups(filteredGroups);
    };

    loadGroups();
  }, [targetProfileGroupId]);

  return (
    <>
      {userProfileGroups && (
        <SlideoutLayout
          title={t('Copy members from another group')}
          cancel={cancel}
          done={done}
          bar={
            <Button
              key="submit"
              text={t('Save')}
              className="btn btn-primary"
              onClick={handleSubmit(onSubmit)}
            />
          }
        >
          <FormSelect
            register={register}
            required
            error={errors.targetGroupId}
            errorMessage={t('Source group is required')}
            name="sourceProfileGroupId"
            label={t('Source group')}
          >
            <option value="">{t('--- choose ---')}</option>
            {userProfileGroups.map((group) => (
              <option key={group.id} value={group.id}>
                {group.name}
              </option>
            ))}
          </FormSelect>
        </SlideoutLayout>
      )}
    </>
  );
}

CopyMembers.propTypes = {
  targetProfileGroupId: PropTypes.string,
  done: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {};
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CopyMembers);
