import React from 'react';

function CurrencyHeaderCell({ currency }) {
  return (
    <div className="grid-header">
      <div className="no-border text-right">
        <small>{currency.name}</small>
      </div>
      <div className="no-border text-right" title={currency.name}>
        {currency.code}
      </div>
    </div>
  );
}

export default CurrencyHeaderCell;
