import * as userProfileGroupApi from '../api/userProfileGroupApi';

export function saveUserProfileGroup(userProfileGroup) {
  return userProfileGroupApi.saveUserProfileGroup(userProfileGroup);
}

export function getUserProfileGroupById(id) {
  return userProfileGroupApi.getUserProfileGroupById(id);
}

export function getUserProfileGroups() {
  return userProfileGroupApi.getAllUserProfileGroups();
}
export function loadUserProfileGroups({
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return userProfileGroupApi.getUserProfileGroups(
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function deleteUserProfileGroup(id) {
  return userProfileGroupApi.deleteUserProfileGroup(id);
}

export function addUserToGroup(userProfileGroupId, userProfileId) {
  return userProfileGroupApi.addUserToGroup(userProfileGroupId, userProfileId);
}

export function removeUserFromGroup(userProfileGroupId, userProfileId) {
  return userProfileGroupApi.removeUserFromGroup(
    userProfileGroupId,
    userProfileId
  );
}

export function exportUserProfileGroupData(filters, scopes) {
  return userProfileGroupApi.exportUserProfileGroupData(filters, scopes);
}

// companies
export function getCompanies(userProfileGroupId) {
  return userProfileGroupApi.getCompanies(userProfileGroupId);
}
export function addCompanies(companyIds, userProfileGroupId) {
  return userProfileGroupApi.addCompanies(companyIds, userProfileGroupId);
}
export function deleteCompanies(companyIds, userProfileGroupId) {
  return userProfileGroupApi.deleteCompanies(companyIds, userProfileGroupId);
}

export function getMembers(userProfileGroupId) {
  return userProfileGroupApi.getMembers(userProfileGroupId);
}

export function copyMembers(sourceProfileGroupId, targetProfileGroupId) {
  return userProfileGroupApi.copyMembers(
    sourceProfileGroupId,
    targetProfileGroupId
  );
}
