import React, { useEffect, useState } from 'react';
import { hasPermission, userIs } from '../../auth/authUtils';
import userTypes from '../../../constants/userTypes';
import DashboardStats from '../../common/DashboardStats';
import { useTranslation } from 'react-i18next';

import { scopes as tableScopes } from '../../../constants/scopes';
import permissionTypes from '../../../constants/permissionTypes';

const Stats = ({ id, stats, currentUser }) => {
  const [_stats, _setStats] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (stats) {
      let requester = userIs(currentUser, userTypes.REQUESTER);
      let approver = userIs(currentUser, userTypes.APPROVER);

      let allowedStats = [];

      // requester only
      if (requester) {
        allowedStats.push({
          colour: 'blue',
          linkto: `/requests/made-by-me?${tableScopes.ISPENDING}=true&${tableScopes.EXCLUDEARCHIVEDTITLES}=true&${tableScopes.EXCLUDEARCHIVEDKEYWORKFLOWS}=true`,
          title: t('Your requests - pending'),
          metric: stats.yourOpenRequests,
        });
        allowedStats.push({
          colour: 'blue',
          linkto: `/requests/made-by-me?${tableScopes.EXCLUDEARCHIVEDTITLES}=true&${tableScopes.EXCLUDEARCHIVEDKEYWORKFLOWS}=true`,
          title: t('Your requests - all'),
          metric: stats.yourRequests,
        });
      }

      allowedStats.push({
        colour: 'aqua',
        linkto: `/games`,
        title: t('Games'),
        metric: stats.games,
      });

      // approver only
      if (approver) {
        allowedStats.push({
          colour: 'green',
          linkto: `/requests/approve?${tableScopes.EXCLUDEARCHIVEDTITLES}=true&${tableScopes.EXCLUDEARCHIVEDKEYWORKFLOWS}=true`,
          title: t('Requests you can approve'),
          metric: stats.requestsYouCanAuthorise,
        });
      }
      allowedStats.push({
        colour: 'yellow',
        linkto: `/requests/all?f_requestStatus=pending%20keys&${tableScopes.EXCLUDEARCHIVEDTITLES}=true&${tableScopes.EXCLUDEARCHIVEDKEYWORKFLOWS}=true`,
        title: t('Requests - needing keys'),
        metric: stats.requestsNeedingKeys,
      });
      allowedStats.push({
        colour: 'yellow',
        linkto: `/requests/all?f_requestStatus=keys%20ordered&${tableScopes.EXCLUDEARCHIVEDTITLES}=true&${tableScopes.EXCLUDEARCHIVEDKEYWORKFLOWS}=true`,
        title: t('Requests - keys ordered'),
        metric: stats.keysOrdered,
      });

      if (hasPermission(currentUser, permissionTypes.MANAGE_KEYS)) {
        allowedStats.push({
          colour: 'red',
          linkto: `/keys/reported-disabled?${tableScopes.TOBEDISABLEDONSTEAM}=true`,
          title: t('Transactions to be disabled on steam'),
          metric: stats.toBeDisabledOnSteam,
        });
      }

      allowedStats.push({
        colour: 'dark-yellow',
        linkto: `/requests/all?${tableScopes.ISPENDING}=true&${tableScopes.EXCLUDEARCHIVEDTITLES}=true&${tableScopes.EXCLUDEARCHIVEDKEYWORKFLOWS}=true`,
        title: t('All open requests'),
        metric: stats.allOpenRequests,
      });
      allowedStats.push({
        colour: 'orange',
        linkto: `/requests/all?${tableScopes.ISPENDING}=true&${tableScopes.ISOVERSLA}=true&${tableScopes.EXCLUDEARCHIVEDTITLES}=true&${tableScopes.EXCLUDEARCHIVEDKEYWORKFLOWS}=true`,
        title: t('Open requests SLA breached'),
        metric: stats.overSLA,
      });
      _setStats(allowedStats);
    }
  }, [id, stats, currentUser]);

  return <DashboardStats stats={_stats} />;
};

export default Stats;
