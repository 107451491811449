import React from 'react';
import PropTypes from 'prop-types';
import { CurrencySymbol } from '../../../../../util/formatter';

function GameCurrencyNameCellEditable({
  currency,
  game,
  onChange,
  locked,
  className,
  onCursor,
}) {
  const [price, setPrice] = React.useState('');

  const localOnChange = (value, game) => {
    setPrice(value);
  };

  const localOnBlur = (game) => {
    if (price === '') {
      return;
    }

    // single change
    const newPrice = parseFloat(price);
    if (isNaN(newPrice)) {
      setPrice('');
      return;
    }
    onChange(game, currency, newPrice);
    setPrice('');
  };

  return (
    <div className={'grid-empty full-cell grid-price p-0 num sku sku-input'}>
      {CurrencySymbol(currency.culture, currency.code.split('_')[0], 'ms-2')}
      <input
        type="text"
        value={price}
        readOnly={locked ? 'readOnly' : ''}
        onBlur={() => {
          localOnBlur(game);
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === 'Tab') {
            localOnBlur(game);
          }
          // if it's an arrow key then call onCursor
          if (onCursor) {
            if (
              e.key === 'ArrowUp' ||
              e.key === 'ArrowDown' ||
              e.key === 'ArrowLeft' ||
              e.key === 'ArrowRight'
            ) {
              onCursor(e.key);
            }
          }
        }}
        placeholder={!game.isSKU ? currency.code : '0.00'}
        className={`text-right form-control num ${className}`}
        onChange={(e) => localOnChange(e.target.value, game)}
        disabled={game.showRemove}
      ></input>
    </div>
  );
}

GameCurrencyNameCellEditable.propTypes = {
  currency: PropTypes.object.isRequired,
  skuPrice: PropTypes.number,
  skuDraftPrice: PropTypes.number,
  game: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  locked: PropTypes.bool,
  className: PropTypes.string,
  onCursor: PropTypes.func,
};

export default GameCurrencyNameCellEditable;
