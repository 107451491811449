import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, Link } from 'react-router-dom';
import KwPanel from '../../../common/KwPanel';
import { cellClick } from '../../../../util/table';

import image_request_complete from '../../../../content/images/request_icons/request_complete.svg';
import image_no_content_steps from '../../../../content/images/no-content_steps.svg';
import image_aurora from '../../../../content/images/AURORA_LOGO_32_01_LANDSCAPE.svg';

import Button from '../../../common/Button';
import { useTranslation } from 'react-i18next';
import YesNoLabel from '../../../common/YesNoLabel';

const WorkflowSteps = ({ onEdit, steps, workflow, isAuroraWorkflow }) => {
  const { t } = useTranslation();
  return (
    <KwPanel className="mb-4">
      <header className="mb-2">
        <h2 className="no-margin">
          <span className="workflow-icon">
            <img
              src={image_request_complete}
              alt={t('Workflow steps icon')}
              width="32"
              height="32"
            />
          </span>
          {t('Workflow steps')}
        </h2>
        {!isAuroraWorkflow && (
          <div className="float-right">
            <Link
              to={`/reports/workflows-approvers?f_keyWorkflowName=${workflow.keyWorkflowName}`}
              className="btn btn-default"
            >
              {t('View all approvers')}
            </Link>
            {steps && steps.length > 0 ? (
              <Button
                onClick={onEdit}
                className="btn btn-default ms-2"
                text={t('Edit')}
              />
            ) : (
              <Button
                onClick={onEdit}
                className="btn btn-default ms-2"
                text={t('Add')}
              />
            )}
          </div>
        )}
      </header>
      {!isAuroraWorkflow && steps && steps.length > 0 ? (
        <table className="table dataTable width-100">
          <thead>
            <tr>
              <th>{t('Sequence')}</th>
              <th>{t('Name')}</th>
              <th>{t('Minimum signatures')}</th>
              <th>{t('# Users')}</th>
              <th>{t('Allow key upload if approved')}</th>
              <th>{t('Applies to requests')}</th>
            </tr>
          </thead>
          <tbody>
            {steps.map((step) => {
              return (
                <tr key={`${step.id}`}>
                  <td onClick={cellClick} className="num">
                    {step.sequence}
                  </td>
                  <td onClick={cellClick}>
                    <NavLink
                      to={`/settings/user-profile-group/${step.userProfileGroupId}`}
                    >
                      {step.groupName}
                    </NavLink>
                  </td>
                  <td onClick={cellClick} className="num">
                    {step.requiredSignatures}
                  </td>
                  <td onClick={cellClick} className="num">
                    <NavLink
                      to={`/settings/user-profile-group/${step.userProfileGroupId}/members`}
                    >
                      {step.memberCount}
                    </NavLink>
                  </td>
                  <td onClick={cellClick} className="num">
                    <YesNoLabel value={step.allowKeyUploadOnApproval} />
                  </td>
                  <td onClick={cellClick}>
                    {step.applicableType === 'A' ? (
                      <span className="badge bg-info">{t('Always')}</span>
                    ) : step.applicableType === 'M' ? (
                      <div className="badge bg-success">
                        {t('If more than {count} keys', {
                          count: step.applicableKeyCount,
                        })}
                      </div>
                    ) : step.applicableType === 'L' ? (
                      <div className="badge bg-danger">
                        {t('If less than {count} keys', {
                          count: step.applicableKeyCount,
                        })}
                      </div>
                    ) : (
                      ''
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <div
          className="alert alert-info text-center"
          style={{ padding: '30px 15px' }}
        >
          {isAuroraWorkflow ? (
            <>
              <img
                src={image_aurora}
                alt="Aurora logo"
                height={20}
                className="mb-4"
              />
              <p>{t('Aurora™ workflows dont have any approval steps')}</p>
            </>
          ) : (
            <>
              <img
                src={image_no_content_steps}
                alt="No steps"
                className="mb-2"
              />
              <p>
                {t(
                  'There are no steps defined for this workflow. If no steps are defined, the request will approve automatically.'
                )}
              </p>
            </>
          )}
        </div>
      )}
    </KwPanel>
  );
};

WorkflowSteps.propTypes = {
  onEdit: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      sequence: PropTypes.number.isRequired,
      userProfileGroupId: PropTypes.string.isRequired,
      groupName: PropTypes.string.isRequired,
      requiredSignatures: PropTypes.number.isRequired,
      memberCount: PropTypes.number.isRequired,
      applicableType: PropTypes.string.isRequired,
    }).isRequired
  ),
};

export default WorkflowSteps;
