import React from 'react';
import PropTypes from 'prop-types';
import KeyValue from './KeyValue';
import KeyProvider from './KeyProvider';
import Platform from './Platform';
import PackageId from './PackageId';
import KeyCount from './KeyCount';
import RequesterTag from './RequesterTag';
import Stock from './Stock';
import GameLink from '../../common/links/GameLink';
import Button from '../../common/Button';
import icons from '../../../constants/icons';
import ImageWithFallback from '../../common/ImageWithFallback';
import KebabMenu from '../../common/KebabMenu';
import { CommaNumber_NoDecimal } from '../../../util/formatter';
import { RequestStatusLabel } from './RequestStatusLabel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { isPowerUser } from '../../auth/authUtils';
import AssignedTo from './AssignedTo';

function GamesUncompressedView({
  requests,
  canWithdrawRequest,
  processing,
  onWithdrawRequest,
  canSetStatus,
  onSetStatus,
  canDownloadKeys,
  onDownloadKeys,
  canRequestOnSteam,
  onSteamRequest,
  canUploadKeys,
  onUploadKeys,
  canSetKeysOrdered,
  onKeysOrdered,
  onShowDetails,
  onApproveItem,
  onDeclineItem,
  onEditRequestKeyCount,
  onEditRequestValue,
  canDisableKeys,
  onDisableKeys,
  canAssignRequest,
  onReassign,
  showAllButtons,
  endUserDetailsRequired,
  handleUploadAccountDetails,
  canReturnKeys,
  handleReturnKeys,
  currentUser,
}) {
  const { t } = useTranslation();
  // ================================================================================
  const getClasses = (request, suffix) => {
    let classList = '';
    if (request.requestStatus?.isAuthorised && request.isWaitingForKeys) {
      classList += `authpending ss-${suffix} `;
    } else {
      classList += `${request.requestStatus.cssClasses} ss-${suffix} `;
    }

    return classList;
  };

  // ================================================================================
  return requests ? (
    requests.map((request, i) => (
      <div
        key={request.id}
        className={`panel kw-panel request-panel inner-pad r-corners ${
          i < requests.length - 1 ? 'space-bottom' : ''
        } ${getClasses(request, 'border')}`}
      >
        <header
          className={`r-corners-top-right xs-column ${getClasses(
            request,
            'bg'
          )}`}
        >
          {/* //todo : add file upload by dropping on this */}
          <h4 className="no-margin space-bottom-xs-s">
            <span className="pe-3">{request.reference}</span>
            <RequestStatusLabel
              requestStatus={request.requestStatus}
              isWaitingForKeys={request.isWaitingForKeys}
              isUpdateInProgress={request.isUpdateInProgress}
              isDownloaded={request.downloaded}
              noBadge={true}
            />
          </h4>
          <div className="text-right">
            <KebabMenu
              skip={0}
              take={endUserDetailsRequired ? 3 : 2}
              reverse={true}
              className="inline-icons"
              buttons={[
                canDisableKeys(request) && (
                  <Button
                    isDisabled={processing}
                    className="btn btn-danger btn-xs-full me-2"
                    dropdownclasses="danger"
                    onClick={(e) => onDisableKeys(e, request)}
                    icon={icons.DISABLE_KEYS}
                    text={t('Disable keys')}
                  />
                ),
                canAssignRequest(request) && (
                  <Button
                    isDisabled={processing}
                    className="btn btn-default me-2"
                    onClick={(e) => onReassign(e, request)}
                    icon={icons.REASSIGN}
                    text={t('Reassign')}
                    title={t('Reassign the request to a user')}
                  ></Button>
                ),
                canReturnKeys(request) && (
                  <Button
                    isDisabled={processing}
                    className="btn btn-default me-2"
                    onClick={(e) => handleReturnKeys(e, request)}
                    icon={icons.RETURN_KEYS}
                    text={t('Return keys')}
                    title={t('Return keys back to key pool')}
                  ></Button>
                ),
                canWithdrawRequest(request) && (
                  <Button
                    isDisabled={processing}
                    className="btn btn-danger btn-xs-full me-2"
                    dropdownclasses="danger"
                    onClick={(e) => onWithdrawRequest(e, request)}
                    icon={icons.WITHDRAW_REQUEST}
                    text={t('Withdraw request')}
                  />
                ),

                canSetStatus(request) && (
                  <Button
                    isDisabled={processing}
                    className="btn btn-default btn-xs-full me-2"
                    onClick={(e) => onSetStatus(e, request)}
                    icon={icons.APPROVE}
                    text={t('Set status')}
                  />
                ),

                canDownloadKeys(request) && (
                  <Button
                    isDisabled={processing}
                    className="btn btn-default btn-xs-full me-2"
                    onClick={(e) => onDownloadKeys(e, request)}
                    icon={icons.DOWNLOAD_KEYS}
                    text={t('Download keys')}
                  />
                ),

                canRequestOnSteam(request) && (
                  <Button
                    isDisabled={processing}
                    className="btn btn-default btn-xs-full me-2"
                    onClick={(e) => onSteamRequest(e, request)}
                    icon={icons.REQUEST_ON_STEAM}
                    text={t('Request on Steam')}
                  />
                ),
                canSetKeysOrdered(request) && (
                  <Button
                    isDisabled={processing}
                    className="btn btn-default btn-xs-full me-2"
                    onClick={(e) => onKeysOrdered(e, request)}
                    icon={icons.SET_KEYS_ORDERED}
                    text={t('Keys ordered')}
                  />
                ),
                endUserDetailsRequired && (
                  <Button
                    className="btn btn-default btn-xs-full me-2"
                    onClick={() =>
                      handleUploadAccountDetails(
                        request.id,
                        request.numberOfKeys
                      )
                    }
                    icon={icons.UPLOAD_ACCOUNT_DETAILS}
                    text={t('Upload account details')}
                  />
                ),
                canUploadKeys(request) && (
                  <Button
                    isDisabled={processing}
                    className="btn btn-default btn-xs-full me-2"
                    onClick={(e) => onUploadKeys(e, request)}
                    icon={icons.UPLOAD_KEYS}
                    text={t('Upload keys')}
                  />
                ),
                <Button
                  key={request.id}
                  tabIndex={0}
                  isDisabled={processing}
                  className="btn btn-default btn-xs-full me-2"
                  onClick={(e) => onShowDetails(e, request)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      onShowDetails(e, request);
                    }
                  }}
                  icon={icons.INFO}
                  text={t('Details')}
                />,
              ]}
            ></KebabMenu>
          </div>
        </header>
        <div className="content">
          <div className="space-bottom clearfix">
            <div className="float-left-sm-up space-bottom-xs">
              <div className="d-flex align-items-center">
                {request.packshotUrl && (
                  <div className="game-img-wrapper me-4">
                    <ImageWithFallback
                      url={request.packshotUrl}
                      alt={request.gameTitle}
                      fallBackImage={
                        <div className="game-img">
                          <div className="icon-wrapper">
                            <FontAwesomeIcon
                              icon={icons.GAME}
                              className="game"
                            />
                          </div>
                        </div>
                      }
                    ></ImageWithFallback>
                  </div>
                )}
                <GameLink
                  gameId={request.gameId}
                  skuId={request.skuId}
                  gameTitle={request.gameTitle}
                  skuName={request.skuName}
                  key={request.id}
                  currentUser={currentUser}
                />
              </div>
            </div>
            <div
              className="btn-group float-right-sm-up space-row-xs space-row-even "
              aria-label="Per-item approve options"
            >
              {request.currentUserCanSign && (
                <>
                  <Button
                    isDisabled={processing}
                    title={processing ? t('Processing') : t('Approve')}
                    onClick={(e) => onApproveItem(e, request)}
                    className={`btn btn-default btn--approval space-right-s-xs ${
                      request.action === 'approve' ? 'active' : ''
                    }`}
                    icon={icons.APPROVE}
                    hideTitle={true}
                    text={t('Approve')}
                  />
                  <Button
                    isDisabled={processing}
                    title={processing ? t('Processing') : t('Decline')}
                    onClick={(e) => onDeclineItem(e, request)}
                    className={`btn btn-default btn--decline space-left-s-xs ${
                      request.action === 'decline' ? 'active' : ''
                    }`}
                    icon={icons.DECLINE}
                    hideTitle={true}
                    text={t('Decline')}
                  />
                </>
              )}
              {request.currentUserHasSigned && (
                <span className="badge bg-success r-corners">
                  {request.signedDate}
                </span>
              )}
            </div>
          </div>

          <div className="row">
            <KeyCount
              request={request}
              processing={processing}
              onEditRequestKeyCount={onEditRequestKeyCount}
              compressed={false}
            ></KeyCount>
            <KeyValue
              request={request}
              processing={processing}
              onEditRequestValue={onEditRequestValue}
              compressed={false}
            ></KeyValue>
            <KeyProvider request={request} compressed={false}></KeyProvider>
            <Platform request={request} compressed={false}></Platform>
            <PackageId
              request={request}
              currentUser={currentUser}
              compressed={false}
            ></PackageId>
            <RequesterTag request={request}></RequesterTag>

            {isPowerUser(currentUser) ? (
              <AssignedTo
                request={request}
                onReassign={onReassign}
                processing={processing}
              />
            ) : (
              <></>
            )}
            {request.isCurrentUserRequest && <Stock request={request}></Stock>}

            {endUserDetailsRequired && (
              <div className="col-6">
                <label className="col-form-label">
                  {t('Account details uploaded')}
                </label>
                <div className="flex ps-0">
                  <div
                    tabIndex={0}
                    className="form-control-static px-3 flex h-100 w-100 align-items-center"
                    onClick={() =>
                      handleUploadAccountDetails(
                        request.id,
                        request.numberOfKeys
                      )
                    }
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleUploadAccountDetails(
                          request.id,
                          request.numberOfKeys
                        );
                      }
                    }}
                  >
                    {request.accountDetails !== request.numberOfKeys && (
                      <FontAwesomeIcon
                        icon={icons.ARE_YOU_SURE}
                        className="me-3 c-orange"
                      />
                    )}
                    <span className="a">
                      {t('{accountDetails} of {numberOfKeys}', {
                        accountDetails: CommaNumber_NoDecimal(
                          request.accountDetails
                        ),
                        numberOfKeys: CommaNumber_NoDecimal(
                          request.numberOfKeys
                        ),
                      })}
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>

          {request.gameNotesHTML && (
            <div
              className={`row ${request.skuNotesHTML ? 'space-bottom' : ''}`}
            >
              <div className="col-12 ps-4">
                <div className="row">
                  <label className="col-form-label">{t('Game notes')}</label>
                  <div className="flex ps-0">
                    <div
                      className="form-control-static px-3 flex h-100 w-100 align-items-center"
                      dangerouslySetInnerHTML={{
                        __html: request.gameNotesHTML,
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {request.skuNotesHTML && (
            <div className="row">
              <div className="col-12 ps-4">
                <div className="row">
                  <label className="col-form-label">{t('SKU notes')}</label>
                  <div className="flex ps-0">
                    <div
                      className="form-control-static px-3 flex h-100 w-100 align-items-center"
                      dangerouslySetInnerHTML={{ __html: request.skuNotesHTML }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    ))
  ) : (
    <></>
  );
}

GamesUncompressedView.propTypes = {
  requests: PropTypes.array.isRequired,
  canWithdrawRequest: PropTypes.func.isRequired,
  processing: PropTypes.bool.isRequired,
  onWithdrawRequest: PropTypes.func.isRequired,
  canSetStatus: PropTypes.func.isRequired,
  onSetStatus: PropTypes.func.isRequired,
  canDownloadKeys: PropTypes.func.isRequired,
  onDownloadKeys: PropTypes.func.isRequired,
  canRequestOnSteam: PropTypes.func.isRequired,
  onSteamRequest: PropTypes.func.isRequired,
  canUploadKeys: PropTypes.func.isRequired,
  onUploadKeys: PropTypes.func.isRequired,
  canSetKeysOrdered: PropTypes.func.isRequired,
  onKeysOrdered: PropTypes.func.isRequired,
  onShowDetails: PropTypes.func.isRequired,
  onApproveItem: PropTypes.func.isRequired,
  onDeclineItem: PropTypes.func.isRequired,
  onEditRequestKeyCount: PropTypes.func.isRequired,
  onEditRequestValue: PropTypes.func.isRequired,
  canDisableKeys: PropTypes.func.isRequired,
  onDisableKeys: PropTypes.func.isRequired,
  canAssignRequest: PropTypes.func.isRequired,
  onReassign: PropTypes.func.isRequired,
  showAllButtons: PropTypes.bool.isRequired,
  endUserDetailsRequired: PropTypes.bool.isRequired,
  handleUploadAccountDetails: PropTypes.func.isRequired,
  canReturnKeys: PropTypes.func.isRequired,
  handleReturnKeys: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
};

export default GamesUncompressedView;
