import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import UploaderForm from '../components/UploaderForm';
import Delete from './Delete';
import {
  saveUploader,
  getUploaderById,
} from '../../../../actions/uploaderActions';
import Loading from '../../../common/Loading';
import { getAllWorkflows } from '../../../../actions/workflowActions';
import { getKeyProviders } from '../../../../actions/keyProviderActions';
import { getPlatforms } from '../../../../actions/platformActions';
import { getAllCompanies } from '../../../../actions/companyActions';
import { getUserProfileGroups } from '../../../../actions/userProfileGroupActions';
import SlideoutLayout from '../../../layout/slideout/SlideoutLayout';
import SlidingPane from 'react-sliding-pane';
import { getCompanyIdsForAnyPermission } from '../../../auth/authUtils';
import { useTranslation } from 'react-i18next';

function Uploader({
  done,
  id,
  cancel,
  history,
  workflows,
  getAllWorkflows,
  keyProviders,
  getKeyProviders,
  platforms,
  getPlatforms,
  companies,
  getAllCompanies,
  currentUser,
}) {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    getValues,
    clearErrors,
    setValue,
    watch,
  } = useForm();

  //companies
  useEffect(() => {
    if (!companies) {
      getAllCompanies().catch((e) => {
        toast.error(t('Failed to get companies'));
      });
    }
  }, [companies, getAllCompanies]);

  const [allowedPublishers, setAllowedPublishers] = useState();
  useEffect(() => {
    let pubs = getCompanyIdsForAnyPermission(currentUser);
    setAllowedPublishers(pubs);
  }, [companies, currentUser]);

  const onSubmit = (data) => {
    if (!uploader) {
      toast.error(t('Failed to save uploader'));
      return;
    }

    saveUploader({ ...data, id: uploader.id })
      .then(() => {
        setUploader({ ...data, id: uploader.id });
        done();
        toast.success(t('Uploader saved'));
      })
      .catch(() => {
        toast.error(t('Failed to save uploader'));
      });
  };

  const [deleteSlideoutOpen, setDeleteSlideoutOpen] = useState(false);
  const handleDelete = (e) => {
    e.cancelBubble = true;
    setDeleteSlideoutOpen(true);
    return false;
  };

  const [uploader, setUploader] = useState();

  useEffect(() => {
    if (id) {
      getUploaderById(id)
        .then((d) => {
          setUploader(d);
        })
        .catch((e) => {
          console.error(e);
          history.push('/settings/uploaders/');
          toast.error(t('Uploader not found'));
        });
    }
  }, [id, history]);

  useEffect(() => {
    if (!workflows) {
      getAllWorkflows();
    }
  }, [workflows, getAllWorkflows]);

  useEffect(() => {
    if (!keyProviders) {
      getKeyProviders();
    }
  }, [keyProviders, getKeyProviders]);

  useEffect(() => {
    if (!platforms) {
      getPlatforms();
    }
  }, [platforms, getPlatforms]);

  const [groups, setGroups] = useState();
  useEffect(() => {
    if (!groups) {
      // get the groups
      getUserProfileGroups()
        .then((d) => {
          setGroups(d.data);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [groups]);
  return uploader && workflows && keyProviders && platforms && groups ? (
    <>
      <SlideoutLayout
        title={t('Edit uploader')}
        cancel={cancel}
        done={done}
        bar={
          <>
            <input
              type="button"
              value={t('Delete')}
              className="btn btn-danger me-2"
              onClick={handleDelete}
            />
            <input
              type="submit"
              value={t('Save')}
              className="btn btn-primary"
              onClick={handleSubmit(onSubmit)}
            />
          </>
        }
      >
        <UploaderForm
          uploader={uploader}
          register={register}
          errors={errors}
          control={control}
          getValues={getValues}
          setValue={setValue}
          watch={watch}
          workflows={workflows}
          clearErrors={clearErrors}
          keyProviders={keyProviders}
          platforms={platforms}
          groups={groups}
          companies={companies}
          allowedPublishers={allowedPublishers}
        ></UploaderForm>
      </SlideoutLayout>

      <SlidingPane
        isOpen={deleteSlideoutOpen}
        hideHeader={true}
        from="right"
        className="small-side-panel"
        onRequestClose={() => setDeleteSlideoutOpen(false)}
      >
        <Delete
          id={id}
          done={(d) => {
            setDeleteSlideoutOpen(false);
            done();
          }}
          cancel={() => setDeleteSlideoutOpen(false)}
        />
      </SlidingPane>
    </>
  ) : (
    <Loading></Loading>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    workflows: state.workflows,
    keyProviders: state.keyProviders,
    platforms: state.platforms,
    company: state.company,
    companies: state.companies,
    currentUser: state.auth,
  };
}

const mapDispatchToProps = {
  getAllWorkflows,
  getKeyProviders,
  getPlatforms,
  getAllCompanies,
};

export default connect(mapStateToProps, mapDispatchToProps)(Uploader);
