import * as skuPriceApi from '../api/skuPriceApi';
import actionTypes from '../constants/actionTypes';

export function getAllSKUPrices() {
  return skuPriceApi.getAllSKUPrices();
}
export function getAllDraftSKUPrices() {
  return skuPriceApi.getAllDraftSKUPrices();
}

export function getSKUPrices({
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return skuPriceApi.getSKUPrices(
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function getDraftSKUPrices({
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return skuPriceApi.getDraftSKUPrices(
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function getSKUPriceById(skuId, currencyId) {
  return skuPriceApi.getSKUPriceById(skuId, currencyId);
}

export function saveSKUPrice(skuId, currencyId, price) {
  return skuPriceApi.saveSKUPrice(skuId, currencyId, price);
}

export function deleteSKUPrices(skuId, currencyId) {
  return skuPriceApi.deleteSKUPrices(skuId, currencyId);
}

export function deleteAllSKUPrices() {
  return skuPriceApi.deleteAllSKUPrices();
}

export function uploadPrices(record) {
  return skuPriceApi.uploadPrices(record);
}

export function exportPrices(isDraft) {
  return skuPriceApi.exportPrices(isDraft);
}

export function clearUploadResults() {
  return function (dispatch) {
    dispatch({ type: actionTypes.GAMESKU_PRICING_UPLOAD_CLEAR });
  };
}

export function publishDraft() {
  return skuPriceApi.publishDraft();
}
