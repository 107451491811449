import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { hasPermission } from '../../auth/authUtils';
import permissionTypes from '../../../constants/permissionTypes';
import ImageFromApi from '../ImageFromApi';
import icons from '../../../constants/icons';
import { bestContrast } from '../../../util/formatter';
import { useTranslation } from 'react-i18next';

function KeyProviderLink({
  id,
  name,
  currentUser,
  imageId,
  imageSize,
  keyProviders,
  hideImage,
  colourHex,
}) {
  const { t } = useTranslation();

  const [image, setImage] = useState(imageId);
  useEffect(() => {
    if (!imageId && keyProviders) {
      setImage(keyProviders.find((x) => x.id === id)?.imageId);
    }
  }, [imageId, keyProviders, id]);

  const [colour, setColour] = useState(colourHex);
  const [textClass, setTextClass] = useState('text-light');
  useEffect(() => {
    if (!colourHex && keyProviders) {
      let _colour = keyProviders.find((x) => x.id === id)?.colourHex;
      setColour(_colour);

      setTextClass(
        colour ? bestContrast(_colour, 'text-light', 'text-dark') : 'text-light'
      );
    }
  }, [colourHex, textClass, colour, keyProviders, id]);

  const [keyProviderName, setKeyProviderName] = useState(name);
  useEffect(() => {
    if (!name && keyProviders) {
      let _name = keyProviders.find((x) => x.id === id)?.name;
      setKeyProviderName(_name);
    }
  }, [id, name, keyProviders]);

  return (
    <div className={`d-flex align-items-center ${textClass}`}>
      {!hideImage && (id || name) && (
        <ImageFromApi
          imageId={image}
          imageSize={imageSize}
          fallbackIcon={icons.KEY_PROVIDER}
          colourHex={colour}
        ></ImageFromApi>
      )}
      {id && name ? (
        hasPermission(currentUser, permissionTypes.MANAGE_SETTINGS) ? (
          <NavLink
            to={`/settings/key-provider/${id}`}
            title={t('"View key provider"')}
          >
            {name}
          </NavLink>
        ) : (
          name
        )
      ) : keyProviderName ? (
        hasPermission(currentUser, permissionTypes.MANAGE_SETTINGS) ? (
          <NavLink
            to={`/settings/key-provider/${id}`}
            title={t('"View key provider"')}
          >
            {keyProviderName}
          </NavLink>
        ) : (
          keyProviderName
        )
      ) : (
        '-'
      )}
    </div>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.auth,
    keyProviders: state.keyProviders,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(KeyProviderLink);
