const permissionTypes = Object.freeze({
  VIEW_REPORTS: 'VIEW_REPORTS',

  MANAGE_KEYS: 'MANAGE_KEYS',
  MANAGE_GAMES: 'MANAGE_GAMES',
  MANAGE_WORKFLOWS: 'MANAGE_WORKFLOWS',
  MANAGE_USERGROUPS: 'MANAGE_USERGROUPS',
  MANAGE_PERMISSIONS: 'MANAGE_PERMISSIONS',
  MANAGE_USERS: 'MANAGE_USERS',
  MANAGE_COMPANIES: 'MANAGE_COMPANIES',
  MANAGE_REPORTS: 'MANAGE_REPORTS',

  UPLOAD_ACTIVATIONS: 'UPLOAD_ACTIVATIONS',

  KEYS_SEARCH: 'KEYS_SEARCH',

  MANAGE_TERRITORIES: 'MANAGE_TERRITORIES',
  MANAGE_PLATFORMS: 'MANAGE_PLATFORMS',
  MANAGE_SETTINGS: 'MANAGE_SETTINGS',
  MANAGE_TRANSLATIONS: 'MANAGE_TRANSLATIONS',
  REQUEST_ON_BEHALF_OF: 'REQUEST_ON_BEHALF_OF',

  IMPERSONATE_USERS: 'IMPERSONATE_USERS',

  VIEW_PROMOTIONS: 'VIEW_PROMOTIONS',
  MANAGE_PROMOTIONS: 'MANAGE_PROMOTIONS',

  VIEW_PRICING: 'VIEW_PRICING',
  MANAGE_PRICING: 'MANAGE_PRICING',

  UNARCHIVE_GAMES: 'UNARCHIVE_GAMES',
});

export default permissionTypes;
