import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import KwPanel from '../../../common/KwPanel';
import PropTypes from 'prop-types';
import FormStatic from '../../../common/FormStatic';
import FormSelect from '../../../common/FormSelect';
import icons from '../../../../constants/icons';
import { useTranslation } from 'react-i18next';

import image_no_content_steps from '../../../../content/images/no-content_steps.svg';
import Button from '../../../common/Button';
import { ToggleSwitch } from '../../../common/ToggleSwitch';

const StepsForm = ({
  keyWorkflowName,
  userProfileGroups,
  workflowSteps,
  onStepChange,
  cancel,
}) => {
  const { t } = useTranslation();
  const [steps, setSteps] = useState(workflowSteps);
  const [selectedUserProfileGroup, setSelectedUserProfileGroup] = useState();

  const update = (newArr) => {
    setSteps(newArr);
    onStepChange(newArr);
  };
  const handleRequiredSignaturesChange = ({ value, step, index }, props) => {
    let newArr = [...steps];
    newArr[index].requiredSignatures = parseFloat(value.replace(/,/g, '')) || 0;
    update(newArr);
  };
  const handleOnTypeChange = ({ value, step, index }) => {
    let newArr = [...steps];
    newArr[index].applicableType = value;
    update(newArr);
  };
  const handleShowProformaWarningChange = ({ value, step, index }) => {
    let newArr = [...steps];
    newArr[index].showProformaWarning = value;
    update(newArr);
  };
  const handleAllowKeyUploadOnApprovalChange = ({ value, step, index }) => {
    let newArr = [...steps];
    newArr[index].allowKeyUploadOnApproval = value;
    update(newArr);
  };
  const handleCanEditRequestValueChange = ({ value, step, index }) => {
    let newArr = [...steps];
    newArr[index].canEditRequestValue = value;
    update(newArr);
  };
  const handleApplicableKeyCountChange = ({ value, step, index }) => {
    let newArr = [...steps];
    newArr[index].applicableKeyCount = parseFloat(value.replace(/,/g, '')) || 0;
    update(newArr);
  };
  const handleDeleteClick = ({ step, index }) => {
    let newArr = [...steps];
    newArr.splice(index, 1);

    // fix sequence
    newArr.forEach((x, i) => (x.sequence = i + 1));

    update(newArr);
  };
  const handleUserProfileGroupChange = (e) => {
    setSelectedUserProfileGroup(e.target.value);
  };
  const handleUserProfileGroupAdd = () => {
    let newArr = [...steps];
    // push to array
    let selGroup = userProfileGroups.find(
      (x) => x.id === selectedUserProfileGroup
    );
    newArr.push({
      applicableKeyCount: 1,
      applicableType: 'A',
      canEditRequestValue: false,
      userProfileGroupId: selGroup.id,
      groupName: selGroup.name,
      memberCount: selGroup.memberCount,
      requiredSignatures: 1,
      sequence: newArr.length + 1,
      showProformaWarning: false,
    });

    // fix sequence
    newArr.forEach((x, i) => (x.sequence = i + 1));

    update(newArr);
  };

  return (
    <>
      <KwPanel className="mb-4">
        <header>
          <h2>{t('Add step')}</h2>
        </header>
        <FormStatic label={t('Workflow')}>{keyWorkflowName}</FormStatic>
        <FormSelect
          autoFocus
          label={t('Approver')}
          joinedButton={true}
          joinedButtonIcon={icons.ADD}
          joinedButtonClassName="btn-default"
          joinedButtonClick={handleUserProfileGroupAdd}
          joinedButtonTitle={t('Clear')}
          joinedButtonDisabled={!selectedUserProfileGroup}
          onChange={handleUserProfileGroupChange}
        >
          <option value="">{t('--- choose ---')}</option>
          {userProfileGroups
            ? [...userProfileGroups]
                ?.sort((a, b) => a.name.localeCompare(b.name))
                .map((x) => (
                  <option key={x.id} value={x.id}>
                    {x.name}
                  </option>
                ))
            : ''}
        </FormSelect>
      </KwPanel>
      {steps && steps.length > 0 ? (
        <table className="table dataTable width-100">
          <thead>
            <tr>
              <th>{t('Sequence')}</th>
              <th>{t('Group')}</th>
              <th>{t('Users')}</th>
              <th>{t('Minimum signatures')}</th>
              <th>{t('Can edit request value')}</th>
              <th>{t('Show proforma warning')}</th>
              <th>{t('Allow key upload if approved')}</th>
              <th>{t('Only apply this step if')}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {steps.map((step, index) => {
              return (
                <React.Fragment
                  key={`${step.sequence}-${step.userProfileGroupId}`}
                >
                  {step.pendingRequestsAtThisStep > 0 && (
                    <tr style={{ borderBottom: 'none' }}>
                      <td
                        colSpan="7"
                        style={{
                          textAlign: 'center',
                          borderBottom: '0px',
                        }}
                      >
                        {t(
                          '{{step}} is locked because there are requests at this step',
                          { step: step.groupName }
                        )}
                        <NavLink
                          className="ms-3 btn btn-default btn-outline"
                          to={`/requests/all?f_requestStatus=pending+approval&f_keyWorkflowName=${keyWorkflowName}&f_currentUserProfileGroupName=${step.groupName}`}
                        >
                          {t('View requests')}
                        </NavLink>
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td className="num">{step.sequence}</td>
                    <td>
                      <NavLink
                        to={`/settings/user-profile-group/${step.userProfileGroupId}`}
                        onClick={() => cancel()}
                      >
                        {step.groupName}
                      </NavLink>
                    </td>
                    <td className="num">
                      <NavLink
                        to={`/settings/user-profile-group/${step.userProfileGroupId}/members`}
                        onClick={() => cancel()}
                      >
                        {step.memberCount}
                      </NavLink>
                    </td>
                    <td className="num">
                      <select
                        className="form-control"
                        value={step.requiredSignatures || 1}
                        disabled={step.pendingRequestsAtThisStep > 0}
                        onChange={(e) => {
                          handleRequiredSignaturesChange({
                            value: e.target.value,
                            step,
                            index,
                          });
                        }}
                      >
                        {[...Array(20)].map((x, i) => (
                          <option key={++i} value={i}>
                            {i}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td className="no-wrap">
                      <ToggleSwitch
                        disabled={step.pendingRequestsAtThisStep > 0}
                        value={step.canEditRequestValue || false}
                        onChange={(value) =>
                          handleCanEditRequestValueChange({
                            value,
                            step,
                            index,
                          })
                        }
                      />
                    </td>
                    <td className="no-wrap">
                      <ToggleSwitch
                        disabled={step.pendingRequestsAtThisStep > 0}
                        value={step.showProformaWarning || false}
                        onChange={(value) =>
                          handleShowProformaWarningChange({
                            value,
                            step,
                            index,
                          })
                        }
                      />
                    </td>
                    <td className="no-wrap">
                      <ToggleSwitch
                        disabled={step.pendingRequestsAtThisStep > 0}
                        value={step.allowKeyUploadOnApproval || false}
                        onChange={(value) =>
                          handleAllowKeyUploadOnApprovalChange({
                            value,
                            step,
                            index,
                          })
                        }
                      />
                    </td>
                    <td
                      className="no-wrap"
                      style={{
                        width: '350px',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <div className="toggle-group me-2">
                          <label
                            key="A"
                            className={
                              step.applicableType === 'A' ? 'checked' : ''
                            }
                            onClick={() => {
                              if (!step.pendingRequestsAtThisStep > 0) {
                                handleOnTypeChange({ value: 'A', step, index });
                              }
                            }}
                          >
                            {t('Always')}
                          </label>
                          <label
                            key="M"
                            className={
                              step.applicableType === 'M' ? 'checked' : ''
                            }
                            onClick={() => {
                              if (!step.pendingRequestsAtThisStep > 0) {
                                handleOnTypeChange({ value: 'M', step, index });
                              }
                            }}
                          >
                            {t('If more than')}
                          </label>
                          <label
                            key="L"
                            className={
                              step.applicableType === 'L' ? 'checked' : ''
                            }
                            onClick={() => {
                              if (!step.pendingRequestsAtThisStep > 0) {
                                handleOnTypeChange({ value: 'L', step, index });
                              }
                            }}
                          >
                            {t('If less than')}
                          </label>
                        </div>
                        {step.applicableType !== 'A' ? (
                          <>
                            <input
                              disabled={step.pendingRequestsAtThisStep > 0}
                              type="text"
                              className={`form-control me-2`}
                              defaultValue={step.applicableKeyCount || 1}
                              data-lpignore="true"
                              style={{ width: '75px' }}
                              autoComplete="off"
                              onChange={(e) => {
                                handleApplicableKeyCountChange({
                                  value: e.target.value,
                                  step,
                                  index,
                                });
                              }}
                            />
                            <span>{t('Keys')}</span>
                          </>
                        ) : (
                          ''
                        )}
                      </div>
                    </td>
                    <td>
                      <Button
                        isDisabled={step.pendingRequestsAtThisStep > 0}
                        className="btn btn-default"
                        onClick={() => handleDeleteClick({ step, index })}
                        hideTitle={true}
                        text={t('Delete')}
                        title={
                          step.pendingRequestsAtThisStep > 0
                            ? t('Locked')
                            : t('Delete')
                        }
                        icon={`fa-fw ${
                          step.pendingRequestsAtThisStep > 0
                            ? icons.LOCKED
                            : icons.DELETE_TAG
                        }`}
                      />
                    </td>
                  </tr>
                </React.Fragment>
              );
            })}
            <tr>
              <td className="num">{steps.length + 1}</td>
              <td>{t('Approved')}</td>
              <td className="num"></td>
              <td className="num"></td>
              <td className="no-wrap"></td>
              <td className="no-wrap"></td>
              <td className="no-wrap">
                <ToggleSwitch disabled={true} value={true} />
              </td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
      ) : (
        <div
          className="alert alert-info text-center"
          style={{ padding: '30px 15px' }}
        >
          <img
            src={image_no_content_steps}
            alt={t('No steps')}
            className="space-bottom-small"
          />
          <p>
            {t(
              'There are no steps defined for this workflow. If no steps are defined, the request will approve automatically.'
            )}
          </p>
        </div>
      )}
    </>
  );
};

StepsForm.propTypes = {
  workflowSteps: PropTypes.array.isRequired,
  onStepChange: PropTypes.func.isRequired,
};

export default StepsForm;
