import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { scopes as tableScopes } from '../../../constants/scopes';

export default function ViewLink({ result }) {
  const { t } = useTranslation();
  return result.skuId ? (
    <NavLink
      to={`/pricing/all?${tableScopes.SKU}=${result.skuId}`}
      className=""
    >
      {t('View draft')}
    </NavLink>
  ) : result.gameId ? (
    <NavLink
      to={`/pricing/all?${tableScopes.GAME}=${result.gameId}`}
      className=""
    >
      {t('View draft')}
    </NavLink>
  ) : (
    ''
  );
}
