import * as currencyApi from '../api/currencyApi';
import actionTypes from '../constants/actionTypes';

export function getCurrencyById(id) {
  return currencyApi.getCurrencyById(id);
}

export function loadCurrencies({
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return currencyApi.getCurrencies(
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function getAllCurrencies() {
  return function (dispatch) {
    return currencyApi.getAllCurrencies().then((currencies) => {
      dispatch({
        type: actionTypes.LOAD_CURRENCIES,
        currencies,
      });
    });
  };
}
