import React from 'react';
import PropTypes from 'prop-types';
import { CurrencyNumber_WithDecimal } from '../../../../../util/formatter';

function PriceCellReadOnly({
  currency,
  skuPrice,
  skuDraftPrice,
  game,
  showDraft,
}) {
  const [isDraft, setIsDraft] = React.useState(false);
  React.useEffect(() => {
    if (skuDraftPrice && showDraft) {
      setIsDraft(true);
    } else {
      setIsDraft(false);
    }
  }, [game.isDraft, skuDraftPrice]);

  return (
    <div className={'grid-price-readonly num'}>
      {isDraft ? (
        <>
          {CurrencyNumber_WithDecimal(
            skuDraftPrice || 0,
            currency.cultureInfo,
            currency.code.split('_')[0]
          )}
        </>
      ) : (
        <>
          {CurrencyNumber_WithDecimal(
            skuPrice || 0,
            currency.cultureInfo,
            currency.code.split('_')[0]
          )}
        </>
      )}
    </div>
  );
}

PriceCellReadOnly.propTypes = {
  currency: PropTypes.object,
  skuPrice: PropTypes.number,
  skuDraftPrice: PropTypes.number,
  game: PropTypes.object,
};

export default PriceCellReadOnly;
