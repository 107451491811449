import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import TopMenuItem from './menu-components/TopMenuItem';
import PropTypes from 'prop-types';
import Settings from './Settings';
import Logo from './menu-components/Logo';
import MenuIcon from './menu-components/MenuIcon';
//import Notifications from './menu-components/Notifications';
import Basket from './menu-components/Basket';
import permissionTypes from '../../constants/permissionTypes';
import Gravatar from 'react-gravatar';
import Search from './menu-components/Search';
import userTypes from '../../constants/userTypes';
import { userIs } from '../auth/authUtils';
import SlidingPane from 'react-sliding-pane';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import icons from '../../constants/icons';

function NavMenu({
  history,
  currentUser,
  toggleNavMenu,
  basket,
  companyTypes,
  completeRequestSlideoutOpen,
  setCompleteRequestSlideoutOpen,
  location,
}) {
  const { t } = useTranslation();
  const [searchHasFocus, setSearchHasFocus] = useState(false);
  const [settingsSlideoutOpen, setSettingsSlideoutOpen] = useState(false);

  // default company type
  const [defaultCompanyType, setDefaultCompanyType] = useState();
  useEffect(() => {
    if (companyTypes) {
      setDefaultCompanyType(companyTypes.find((x) => x.isDefault));
    }
  }, [companyTypes, setDefaultCompanyType]);

  const menuItems = () => {
    return (
      <>
        <TopMenuItem
          activeif="/request"
          url="/requests/all"
          title={t('Requests')}
          currentUser={currentUser}
        />
        <TopMenuItem
          activeRegex="^(\/game(s\/)?|\/sku\/)"
          url="/games"
          title={t('Games')}
          currentUser={currentUser}
          allowedUserTypes={[
            userTypes.REQUESTER,
            userTypes.UPLOADER,
            userTypes.APPROVER,
            userTypes.ADMIN,
          ]}
        />
        {/* {defaultCompanyType ? (
          <TopMenuItem
            activeif="/compan"
            url={`/companies/?f_companyTypeId=${defaultCompanyType.id}`}
            title="Companies"
            allowedPermissions={[permissionTypes.MANAGE_COMPANIES]}
            currentUser={currentUser}
          />
        ) : ( */}
        <TopMenuItem
          activeif="/compan"
          url="/companies/"
          title={t('Companies')}
          allowedPermissions={[permissionTypes.MANAGE_COMPANIES]}
          currentUser={currentUser}
        />
        {/* )} */}
        <TopMenuItem
          activeif="/key"
          url="/keys"
          title={t('Keys')}
          currentUser={currentUser}
          allowedPermissions={[
            permissionTypes.MANAGE_KEYS,
            permissionTypes.KEYS_SEARCH,
          ]}
        />
        <TopMenuItem
          activeif="/promotion"
          url="/promotions"
          title={t('Promotions')}
          currentUser={currentUser}
          allowedPermissions={[
            permissionTypes.MANAGE_PROMOTIONS,
            permissionTypes.VIEW_PROMOTIONS,
          ]}
        />
        <TopMenuItem
          activeif="/pricing"
          url="/pricing/all"
          title={t('Pricing')}
          currentUser={currentUser}
          allowedPermissions={[
            permissionTypes.MANAGE_PRICING,
            permissionTypes.VIEW_PRICING,
          ]}
        />
        <TopMenuItem
          activeif="/report"
          url="/reports/requests-by-game"
          title={t('Reports')}
          allowedPermissions={[permissionTypes.VIEW_REPORTS]}
          currentUser={currentUser}
        />
        {defaultCompanyType ? (
          <TopMenuItem
            url={`/users/?f_companyTypeId=${defaultCompanyType.id}`}
            activeRegex="^\/user(s)?\/"
            title={t('Users')}
            allowedPermissions={[permissionTypes.MANAGE_USERS]}
            currentUser={currentUser}
          />
        ) : (
          <TopMenuItem
            url="/users/"
            activeRegex="^\/user(s)?\/"
            title={t('Users')}
            allowedPermissions={[permissionTypes.MANAGE_USERS]}
            currentUser={currentUser}
          />
        )}
        <TopMenuItem
          url="/settings/workflows/"
          activeRegex="^\/settings/workflow(s)?\/"
          title={t('Workflow')}
          allowedPermissions={[permissionTypes.MANAGE_WORKFLOWS]}
          currentUser={currentUser}
        />
      </>
    );
  };

  return (
    <>
      <div className="header-bar">
        <div className="brand">
          <Link tag={Link} to="/">
            <Logo />
          </Link>
        </div>
        <nav className={`nav-bar ${searchHasFocus ? 'search-focus' : ''}`}>
          <ul className="primary-nav hide-phablet">
            {menuItems()}
            <li
              className="float-left dropdown more-menu"
              style={{ display: 'none' }}
            >
              <Link
                tag={Link}
                to="#"
                className="dropdown-toggle no-pad-tb bold"
                data-toggle="dropdown"
              >
                More <FontAwesomeIcon icon={icons.CARET_DOWN} />
              </Link>
              <ul className="dropdown-menu" role="menu">
                {menuItems()}
              </ul>
            </li>
          </ul>

          <ul className="usr-nav">
            {currentUser.userType > 0 && (
              <li className="search-wrapper hide-phablet">
                <Search
                  history={history}
                  onFocus={() => {
                    setSearchHasFocus(true);
                  }}
                  onBlur={() => {
                    setSearchHasFocus(false);
                  }}
                ></Search>
                <div className="search-icon">
                  <FontAwesomeIcon icon={icons.SEARCH} />
                </div>
              </li>
            )}
            <li className="hide-sm-up">
              <Link
                tag={Link}
                to=""
                id="off-canvas-toggle"
                className="no-bg"
                onClick={toggleNavMenu}
              >
                <MenuIcon />
              </Link>
            </li>

            {/* <li className="notifications me-3">
            <Notifications />
          </li> */}
            {userIs(currentUser, userTypes.REQUESTER) && (
              <li className="basket">
                <Basket
                  basket={basket}
                  setCompleteRequestSlideoutOpen={
                    setCompleteRequestSlideoutOpen
                  }
                />
              </li>
            )}
            <li
              className="usr-details hide-phablet a"
              tag="open-settings"
              onClick={() => setSettingsSlideoutOpen(true)}
            >
              <Gravatar
                email={currentUser?.username}
                size={40}
                rating="pg"
                default="retro"
                className="img-circle profile-img"
              />
            </li>
          </ul>
        </nav>
      </div>
      <SlidingPane
        isOpen={settingsSlideoutOpen}
        hideHeader={true}
        from="right"
        className="side-panel"
        onRequestClose={() => setSettingsSlideoutOpen(false)}
      >
        <Settings
          currentUser={currentUser}
          done={() => setSettingsSlideoutOpen(false)}
          cancel={() => setSettingsSlideoutOpen(false)}
          location={location}
        />
      </SlidingPane>
    </>
  );
}

NavMenu.propTypes = {
  currentUser: PropTypes.object,
  basket: PropTypes.object,
  toggleNavMenu: PropTypes.func.isRequired,
};

export default withRouter(NavMenu);
