import { t } from 'i18next';
import React from 'react';

const NoPackageId = () => {
  return (
    <span className="flex justify-content-center" title={t('No Package Id')}>
      -
    </span>
  );
};
export default NoPackageId;
