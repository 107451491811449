export const scopes = Object.freeze({
  ASSIGNEDTO: 's_assignedTo',
  ASSIGNEDTOME: 's_assignedToMe',
  COMPANY: 's_company',
  GAME: 's_game',
  KEYWORKFLOW: 's_keyWorkflow',
  KEYPROVIDER: 's_keyProvider',
  MADEBYME: 's_madeByMe',
  PLATFORM: 's_platform',
  SKU: 's_sku',
  USER: 's_user',
  REQUESTINGGROUP: 's_requestingGroup',
  APPROVINGGROUP: 's_approvingGroup',
  PACKAGETYPE: 's_packageType',
  ISAUTHORISED: 's_isAuthorised',
  ISPENDING: 's_isPending',
  EXCLUDEARCHIVEDTITLES: 's_excludeArchivedTitles',
  ISDOWNLOADED: 's_isDownloaded',
  ACCOUNTDETAIL: 's_accountDetail',
  ISOVERSLA: 's_isOverSLA',
  EXCLUDEARCHIVEDKEYWORKFLOWS: 's_excludeArchivedKeyWorkflows',
  REQUESTERSGROUP: 's_requestersGroup',
  ISARCHIVED: 's_isArchived',
  AUTHORISEDBY: 's_authorisedBy',
  UPLOADEDBY: 's_uploadedBy',
  ISDISABLEDONSTEAM: 's_isDisabledOnSteam',
  TOBEDISABLEDONSTEAM: 's_toBeDisabledOnSteam',
});
