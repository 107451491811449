import React, { useState } from 'react';
import {
  loadMoveTransactions,
  exportMoveTransactionData,
} from '../../../actions/keyCodeActions';
import { Link, NavLink } from 'react-router-dom';
import {
  Table,
  SimpleTextFilter,
  getTableSettingsDefaults,
} from '../../common/Table';
import { CommaNumber_NoDecimal, Date_AsString } from '../../../util/formatter';
import Loading from '../../common/Loading';
import KwPanel from '../../common/KwPanel';
import useTableSettings from '../../../hooks/useTableSettings';
import TransactionStatusBadge from './TransactionStatusBadge';
import GameLink from '../../common/links/GameLink';
import UserLink from '../../common/links/UserLink';
import { useTranslation } from 'react-i18next';

function MovedList({ location, reload, currentUser }) {
  const allColumns = [
    'creationDate',
    'numberOfKeys',
    'gameTitle',
    'sourceKeyWorkflowName',
    'destinationKeyWorkflowName',
    'user',
    'status',
    'description',
  ];
  const defaultVisibleColumns = allColumns;
  const { t } = useTranslation();

  const [transactions, setTransactions] = useState();
  const params = new URLSearchParams(location.search);
  const { tableSettings, setTableSettings, filters, filterChange, isLoading } =
    useTableSettings(
      'movedlist-settings',
      getTableSettingsDefaults({
        defaultSort: 'creationDate',
        direction: 'desc',
        defaultVisibleColumns,
      }),
      null,
      loadMoveTransactions,
      setTransactions,
      reload,
      t('Failed to load move transactions'),
      location
    );

  // The base config of the columns, this will be filtered based on the visible (above)
  let columnsConfig = [
    {
      Key: 'creationDate',
      Label: t('Uploaded date'),
      width: '10%',
      Render: (item) => (
        <Link to={'/keys/moved/' + item.id}>
          {Date_AsString(item.creationDate)}
        </Link>
      ),
    },
    {
      Key: 'numberOfKeys',
      Label: t('Key count'),
      width: '10%',
      className: 'text-right num no-wrap',
      Render: (item) =>
        item.numberOfKeys >= 0
          ? CommaNumber_NoDecimal(item.numberOfKeys)
          : '--',
    },
    {
      Key: 'gameTitle',
      Label: t('Game'),
      width: '20%',
      className: '',
      Render: (item) => (
        <GameLink
          gameId={item.gameId}
          skuId={item.skuId}
          gameTitle={item.gameTitle}
          skuName={item.skuName}
          key={item.moveTransactionId}
          currentUser={currentUser}
        />
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'sourceKeyWorkflowName',
      Label: t('Source'),
      width: '10%',
      className: '',
      Render: (item) => (
        <NavLink to={`/settings/workflow/${item.sourceKeyWorkflowId}`}>
          {item.sourceKeyWorkflowName}
        </NavLink>
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'destinationKeyWorkflowName',
      Label: t('Destination'),
      width: '10%',
      className: '',
      Render: (item) => (
        <NavLink to={`/settings/workflow/${item.destinationKeyWorkflowId}`}>
          {item.destinationKeyWorkflowName}
        </NavLink>
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'user',
      Label: t('Uploaded by'),
      width: '10%',
      className: '',
      Render: (item) => (
        <>
          <UserLink id={item.userId} name={item.user} />
          <br />
          {item.userCompany}
        </>
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'status',
      Label: t('Status'),
      width: '5%',
      className: '',
      Render: (item) => (
        <TransactionStatusBadge
          success={item.success}
          partialSuccess={item.partialSuccess}
          message={item.message}
          status={item.status}
        ></TransactionStatusBadge>
      ),
      Filter: {
        Render: (col) => (
          <select
            className={`form-control ${
              params.get(`f_${[col.Key]}`) ? 'filtered' : ''
            }`}
            value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(el) => filterChange(col.Key, el.target.value)}
          >
            <option value="">{t('All')}</option>
            <option value="failed">{t('Failed')}</option>
            <option value="partial success">{t('Partial success')}</option>
            <option value="pending authorisation">
              {t('Pending authorisation')}
            </option>
            <option value="pending">{t('Pending')}</option>
            <option value="success">{t('Success')}</option>
          </select>
        ),
      },
    },
    {
      Key: 'description',
      Label: t('Description'),
      width: '25%',
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
  ];

  // filter
  const visibleColumns = tableSettings.columns ?? defaultVisibleColumns;
  const columns = visibleColumns
    .map((x) => columnsConfig.find((y) => y.Key === x))
    .filter((x) => x);

  // return an alaska table
  return transactions ? (
    <KwPanel className="mb-4">
      <Table
        columns={columns}
        allColumns={allColumns}
        rows={transactions.data.map((x) => {
          return { Key: x.id, ...x };
        })}
        totalRowCount={transactions.totalRowCount}
        filteredRowCount={transactions.filteredRowCount}
        scopes={transactions.scopes}
        tableSettings={tableSettings}
        setTableSettings={setTableSettings}
        isLoading={isLoading}
        filters={filters}
        filterChange={filterChange}
        exportData={exportMoveTransactionData}
      />
    </KwPanel>
  ) : (
    <Loading></Loading>
  );
}

export default MovedList;
