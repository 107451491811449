import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { signinRedirect, getUser } from '../../services/userService';
import { storeUser, clearProperties } from '../../actions/authActions';
import { useTranslation } from 'react-i18next';

import { showLoader, hideLoader } from '../../actions/loaderActions';
import { toast } from 'react-toastify';
import { usePageVisibility } from '../../hooks/usePageVisibility';
import { pageActive, pageInactive } from '../../actions/signalRActions';
function MustAuth({
  currentUser,
  location,
  children,
  hideLoader,
  showLoader,
  history,
  storeUser,
  clearProperties,
  pageActive,
  pageInactive,
}) {
  const { t } = useTranslation();
  useEffect(() => {
    if (currentUser == null) {
      getUser().then((user) => {
        if (!user || user.expired) {
          signinRedirect(`${location.pathname}${location.search}`).catch(
            (e) => {
              history.push('/Access-Denied');
            }
          );
        } else {
          storeUser(user);
        }
      });
    }

    if (currentUser && currentUser.accessDenied) {
      clearProperties().then(() => history.push('/Access-Denied'));
    } else if (currentUser && currentUser.apiFailure) {
      clearProperties().then(() => history.push('/Api-Failure'));
    } else if (currentUser && currentUser.trialEnded) {
      clearProperties().then(() => history.push('/trial-ended'));
    } else if (currentUser && currentUser.permissionsChanged) {
      clearProperties().then(() =>
        toast.warning(t('Your permissions have been updated'))
      );
    } else if (currentUser && currentUser.isLoading) {
      showLoader('ALASKA', t('loading...'));
      console.log(
        '%c ##################################### \n        %cALASKA ©2025 - %cK3YS LTD        %c\n ##################################### ',
        'background: #252628; color: #ff006e',
        'background: #252628; color: #ffffff',
        'background: #252628; color: #ffff00',
        'background: #252628; color: #ff006e'
      );
      console.log(
        '%c                                      \n' +
          ' (((((((((((((((((((((((((((((((((((( \n' +
          ' (((((((((((((((((((((((((((((((((((( \n' +
          ' (((((((((((((((((((((((((((((((((((( \n' +
          ' ((((((((%c@@#%c((%c&@@%c(((((%c@@&&@@%c((((((((( \n' +
          ' ((((((((%c@@#%c(%c&@@%c((((((((((%c@@#%c(((((((( \n' +
          ' ((((((((%c@@@@@%c((((((((((%c%&@@%c((((((((( \n' +
          ' ((((((((%c@@#@@@%c((((((((((((%c@@%c(((((((( \n' +
          ' ((((((((%c@@#%c((%c&@@%c(((((%c@@@@@@#%c(((((((( \n' +
          ' (((((((((((((((((((((((((((((((((((( \n' +
          ' ((((((((%c@@#%c((((%c@@%c(((((%c@@@@#%c((((((((( \n' +
          ' (((((((((%c#@@%c(%c#@&%c(((((%c@@%c((((((((((((( \n' +
          ' (((((((((((%c@@@%c((((((((%c&@@@#%c((((((((( \n' +
          ' ((((((((((((%c@#%c(((((((%c@%c((((%c@@%c(((((((( \n' +
          ' ((((((((((((%c@#%c((((((((%c@@@@#%c((((((((( \n' +
          ' (((((((((((((((((((((((((((((((((((( \n' +
          ' (((((((((((((((((((((((((((((((((((( \n' +
          '                                      ',
        'background: #2a2b2e; color: #16beef',
        'background: #2a2b2e; color: #ffffff',
        'background: #2a2b2e; color: #16beef',
        'background: #2a2b2e; color: #ffffff',
        'background: #2a2b2e; color: #16beef',
        'background: #2a2b2e; color: #ffffff',
        'background: #2a2b2e; color: #16beef',
        'background: #2a2b2e; color: #ffffff',
        'background: #2a2b2e; color: #16beef',
        'background: #2a2b2e; color: #ffffff',
        'background: #2a2b2e; color: #16beef',
        'background: #2a2b2e; color: #ffffff',
        'background: #2a2b2e; color: #16beef',
        'background: #2a2b2e; color: #ffffff',
        'background: #2a2b2e; color: #16beef',
        'background: #2a2b2e; color: #ffffff',
        'background: #2a2b2e; color: #16beef',
        'background: #2a2b2e; color: #ffffff',
        'background: #2a2b2e; color: #16beef',
        'background: #2a2b2e; color: #ffffff',
        'background: #2a2b2e; color: #16beef',
        'background: #2a2b2e; color: #ffffff',
        'background: #2a2b2e; color: #16beef',
        'background: #2a2b2e; color: #ffffff',
        'background: #2a2b2e; color: #16beef',
        'background: #2a2b2e; color: #ffffff',
        'background: #2a2b2e; color: #16beef',
        'background: #2a2b2e; color: #ffffff',
        'background: #2a2b2e; color: #16beef',
        'background: #2a2b2e; color: #ffffff',
        'background: #2a2b2e; color: #16beef',
        'background: #2a2b2e; color: #ffffff',
        'background: #2a2b2e; color: #16beef',
        'background: #2a2b2e; color: #ffffff',
        'background: #2a2b2e; color: #16beef',
        'background: #2a2b2e; color: #ffffff',
        'background: #2a2b2e; color: #16beef',
        'background: #2a2b2e; color: #ffffff',
        'background: #2a2b2e; color: #16beef',
        'background: #2a2b2e; color: #ffffff',
        'background: #2a2b2e; color: #16beef',
        'background: #2a2b2e; color: #ffffff',
        'background: #2a2b2e; color: #16beef',
        'background: #2a2b2e; color: #ffffff',
        'background: #2a2b2e; color: #16beef',
        'background: #2a2b2e; color: #ffffff',
        'background: #2a2b2e; color: #16beef',
        'background: #2a2b2e; color: #ffffff',
        'background: #2a2b2e; color: #16beef',
        'background: #2a2b2e; color: #ffffff',
        'background: #2a2b2e; color: #16beef',
        'background: #2a2b2e; color: #ffffff',
        'background: #2a2b2e; color: #16beef'
      );
      return;
    }
    setTimeout(hideLoader, 1500);
  }, [
    currentUser,
    location.pathname,
    location.search,
    hideLoader,
    showLoader,
    history,
    storeUser,
    clearProperties,
  ]);

  const isVisible = usePageVisibility();
  useEffect(() => {
    if (isVisible) {
      pageActive();
    } else {
      pageInactive();
    }
  }, [isVisible, pageActive, pageInactive]);

  return currentUser == null ? '' : React.Children.only(children);
}

function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.auth,
    children: ownProps.children,
  };
}

const mapDispatchToProps = {
  showLoader,
  hideLoader,
  storeUser,
  clearProperties,
  pageActive,
  pageInactive,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MustAuth)
);
