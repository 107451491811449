import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ActionBar from '../layout/ActionBar';
import { getAllCurrencies } from '../../actions/currencyActions';
import {
  getSKUPrices,
  getDraftSKUPrices,
  saveSKUPrice,
  publishDraft,
  exportPrices,
} from '../../actions/skuPriceActions';

import 'react-virtualized/styles.css';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import MultiGrid from 'react-virtualized/dist/commonjs/MultiGrid';
//import { hexToRgbA } from '../../util/formatter';
import { getSKUs } from '../../actions/skuActions';
import Loading from '../common/Loading';
import EmptyCell from './components/grid/cells/EmptyCell';
import PriceCell from './components/grid/cells/PriceCell';
import GameNameCell from './components/grid/cells/GameNameCell';
import GameNameHeaderCell from './components/grid/headers/GameNameHeaderCell';
import CurrencyHeaderCell from './components/grid/headers/CurrencyHeaderCell';
import SKUNameCell from './components/grid/cells/SKUNameCell';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import GameCurrencyNameCell from './components/grid/cells/GameCurrencyNameCell';
import PackageIdCell from './components/grid/cells/PackageIdCell';
import PackageIdHeader from './components/grid/headers/PackageIdHeader';
import GameAppIdCell from './components/grid/cells/GameAppIdCell';
import ResponsiveActionBarButtons from '../common/ResponsiveActionBarButtons';
import Button from '../common/Button';
import SlidingPane from 'react-sliding-pane';
import DeleteAllDrafts from './slideout/Delete';

//https://github.com/bvaughn/react-virtualized/blob/master/docs/Grid.md

function PricingGrid({ currencies, getAllCurrencies, setClasses }) {
  const { t } = useTranslation();

  useEffect(() => {
    setClasses('flex-grow-1');
  }, [setClasses]);

  const [gridState, setGridState] = useState({
    scrollToColumn: 0,
    scrollToRow: 0,
  });

  const STYLE = {
    //outline: '1px solid #3b3a39',
  };
  const STYLE_BOTTOM_LEFT_GRID = {
    //borderRight: '2px solid #3b3a39',
    // backgroundColor: '#f7f7f7',
  };
  const STYLE_TOP_LEFT_GRID = {
    //borderBottom: '2px solid #3b3a39',
    //borderRight: '2px solid #3b3a39',
    fontWeight: 'bold',
  };
  const STYLE_TOP_RIGHT_GRID = {
    //borderBottom: '2px solid #3b3a39',
    fontWeight: 'bold',
  };

  // const _cache = new CellMeasurerCache({
  //   defaultWidth: 150,
  //   minWidth: 75,
  //   minHeight: 60,
  // });

  const [reload, setReload] = useState();
  const [locked, setLocked] = useState();
  const [allSKUs, setAllSKUs] = useState();
  const [allSKUPrices, setAllSKUPrices] = useState();
  const [allDraftSKUPrices, setAllDraftSKUPrices] = useState();
  const [games, setGames] = useState();
  const [showDraft, setShowDraft] = useState(true);
  const [deleteAllDraftsSlideoutOpen, setDeleteAllDraftsSlideoutOpen] =
    useState(false);
  const fixedCols = 2;

  const [filterAppPackageId, setFilterAppPackageId] = useState('');
  const handleFilterAppPackageId = (e) => {
    setFilterAppPackageId(e.target.value);
  };

  const [filterGame, setFilterGame] = useState('');
  const handleFilterGame = (e) => {
    setFilterGame(e.target.value);
  };

  const handleCopyToLive = () => {
    setLocked(true);
    // copy draft to live
    publishDraft()
      .then((d) => {
        // toast
        toast.success(d.message ? d.message : t('Draft prices copied to live'));
        setReload(new Date());
        setLocked(false);
      })
      .catch((e) => {
        toast.error(e.message ? e.message : t('Failed to copy draft to live'));
        setLocked(false);
      });
  };

  const handleExportDraft = () => {
    // export draft
    exportPrices(true)
      .then((d) => {})
      .catch((e) => {
        toast.error(e.message ? e.message : t('Failed to export'));
      });
  };

  const handleExportLive = () => {
    // export live
    exportPrices(false)
      .then((d) => {})
      .catch((e) => {
        toast.error(e.message ? e.message : t('Failed to export'));
      });
  };

  const mapGame = (game) => ({
    gameId: game.gameId,
    name: game.gameName,
    isSKU: false,
    packshotUrl: game.packshotUrl,
    appId: game.appId,
    skuNames: game.skUs?.map((sku) => sku.name).flat(),
    packageIds: game.skUs?.map((sku) => sku.packageId).flat(),
    keyProviders: game.skUs?.map((sku) => sku.keyProviderId).flat(),
    platforms: game.skUs?.map((sku) => sku.platformId).flat(),
    currencies: [],
    draftCurrencies: [],
  });

  const mapSKU = (sku, game) => ({
    skuId: sku.id,
    name: sku.name,
    isSKU: true,
    gameId: game.gameId,
    gameName: game.gameName,
    appId: game.appId,
    packageId: sku.packageId,
    packageType: sku.packageType,
    platformId: sku.platformId,
    platform: sku.platform,
    platformImageId: sku.platformImageId,
    platformColourHex: sku.platformColourHex,
    keyProviderId: sku.keyProviderId,
    keyProviderName: sku.keyProviderName,
    keyProviderImageId: sku.keyProviderImageId,
    keyProviderColourHex: sku.keyProviderColourHex,
    territoriesRestricted: sku.territoriesRestricted,
    currencies: [],
    draftCurrencies: [],
  });

  // All SKUs - get this once
  useEffect(() => {
    if (!allSKUs) {
      getSKUs().then((skus) => {
        if (skus == null || skus.data == null) {
          return;
        }
        setAllSKUs(skus.data.filter((x) => !x.isArchived));
      });
    }
  }, [allSKUs, setAllSKUs]);

  // All currencies - get this once
  useEffect(() => {
    if (!currencies) {
      getAllCurrencies();
    }
  }, [currencies, getAllCurrencies]);

  // All SKU Prices - get this once
  useEffect(() => {
    getSKUPrices({
      filters: {},
      _sort: 'game',
      _order: 'ASC',
      _page: 0,
      _limit: 100_000,
    })
      .then((r) => {
        getDraftSKUPrices({
          filters: {},
          _sort: 'game',
          _order: 'ASC',
          _page: 0,
          _limit: 100_000,
        })
          .then((r2) => {
            setAllDraftSKUPrices(r2.data);
            setAllSKUPrices(r.data);
          })
          .catch((e) => {
            toast.error(
              e.message ? e.message : t('Failed to get draft SKU prices')
            );
          });
      })
      .catch((e) => {
        toast.error(e.message ? e.message : t('Failed to get live SKU prices'));
      });
  }, [reload, t]);

  // state to store if we can publish to live
  const [hasDraftPrices, setHasDraftPrices] = useState(false);
  // effect to see if there is anything in the draft prices, then we can publish
  useEffect(() => {
    if (allDraftSKUPrices && allDraftSKUPrices.length > 0) {
      setHasDraftPrices(true);
    } else {
      setHasDraftPrices(false);
    }
  }, [allDraftSKUPrices]);

  // transform the grid data
  useEffect(() => {
    if (currencies && allSKUs && allSKUPrices && allDraftSKUPrices) {
      const loweredGameName = filterGame?.toLowerCase() ?? '';
      const loweredPackageAppId = filterAppPackageId?.toLowerCase() ?? '';

      const matchSKUGameName = (game) =>
        game.gameName?.toLowerCase().indexOf(loweredGameName) !== -1 ||
        game.name?.toLowerCase().indexOf(loweredGameName) !== -1;

      const matchSKUAppPackageId = (game) =>
        game.packageId?.toLowerCase().indexOf(loweredPackageAppId) !== -1 ||
        game.appId?.toLowerCase().indexOf(loweredPackageAppId) !== -1;

      let filteredSKUs = allSKUs.filter((x) => {
        if (filterGame && filterAppPackageId) {
          return matchSKUGameName(x) && matchSKUAppPackageId(x);
        } else if (filterGame) {
          return matchSKUGameName(x);
        } else if (filterAppPackageId) {
          return matchSKUAppPackageId(x);
        }
        return true;
      });

      // get a list of games from skus
      let games = allSKUs.map((s) => ({
        gameName: s.gameName,
        gameId: s.gameId,
        appId: s.appId,
      }));

      // distinct them
      games = games.filter(
        (v, i, a) => a.findIndex((t) => t.gameId === v.gameId) === i
      );

      // only where the game exists in filteredSKUs
      games = games.filter((g) =>
        filteredSKUs.find((x) => x.gameId === g.gameId)
      );

      let gameData = games
        .map((game) => [
          mapGame(game),
          ...filteredSKUs
            .filter((v, i, a) => v.gameId === game.gameId)
            .map((sku) => mapSKU(sku, game)),
        ])
        .flat();

      if (allSKUPrices) {
        allSKUPrices.map((item, index) => {
          let game = gameData.find((g) => g.isSKU && g.skuId === item.skuId);
          if (game != null) {
            game.currencies.push({
              id: item.currencyId,
              price: item.price,
              saving: false,
            });
          }
          return true;
        });
      }

      if (allDraftSKUPrices) {
        allDraftSKUPrices.map((item) => {
          let game = gameData.find((g) => g.isSKU && g.skuId === item.skuId);
          if (game != null) {
            game.draftCurrencies.push({
              id: item.currencyId,
              price: item.price,
              saving: false,
            });
          }
          return true;
        });
      }

      setGames(gameData);
    }
  }, [
    currencies,
    allSKUs,
    allSKUPrices,
    allDraftSKUPrices,
    filterGame,
    filterAppPackageId,
    t,
  ]);

  const saveDirtyPrices = (dirtyPrices) => {
    if (dirtyPrices && dirtyPrices.length > 0) {
      let saved = 0;
      dirtyPrices.forEach((price) => {
        // save price - todo, make this a batch update
        saveSKUPrice(price.skuId, price.currencyId, price.price).then(() => {
          saved++;
          if (saved === dirtyPrices.length) {
            setReload(new Date());
          }
        });
      });
    }
  };

  const onChange = (game, currency, price) => {
    let dirtyPrices = [];
    let newGames = [...games];
    newGames
      .filter((g) => g.isSKU && g.skuId === game.skuId)
      .forEach((s) => {
        let skuCurrency = s.draftCurrencies.find((c) => c.id === currency.id);
        if (!skuCurrency) {
          skuCurrency = {
            id: currency.id,
            price: price,
            saving: true,
          };
          s.draftCurrencies.push(skuCurrency);
        } else {
          skuCurrency.price = price;
          skuCurrency.saving = true;
        }
        dirtyPrices.push({
          skuId: s.skuId,
          currencyId: currency.id,
          price: price,
        });
      });
    setGames(newGames);
    saveDirtyPrices(dirtyPrices);
  };

  const cursorNavigate = ({ key, rowIndex, columnIndex }) => {
    // based on the key pressed, move up down left or right
    let newRowIndex = rowIndex;
    let newColumnIndex = columnIndex;
    if (key === 'ArrowDown') {
      newRowIndex++;
    } else if (key === 'ArrowUp') {
      newRowIndex--;
    } else if (key === 'ArrowRight') {
      newColumnIndex++;
    } else if (key === 'ArrowLeft') {
      newColumnIndex--;
    }
    if (newColumnIndex > 0 && newRowIndex > 0) {
      setGridState({
        scrollToColumn: newColumnIndex,
        scrollToRow: newRowIndex,
      });
      // and focus on the text box based on the class `grid-t-${newColumnIndex}-${newRowIndex}`
      let element = document.querySelector(
        `.grid-t-${newColumnIndex}-${newRowIndex}`
      );
      if (element) {
        element.focus();
      }
    }
  };

  const bulkChange = (game, currency, price) => {
    let dirtyPrices = [];
    let newGames = [...games];
    newGames
      .filter((g) => g.isSKU && g.gameId === game.gameId)
      .forEach((s) => {
        let skuCurrency = s.draftCurrencies.find((c) => c.id === currency.id);
        if (!skuCurrency) {
          skuCurrency = {
            id: currency.id,
            price: price,
            saving: true,
          };
          s.draftCurrencies.push(skuCurrency);
        } else {
          skuCurrency.price = price;
          skuCurrency.saving = true;
        }
        dirtyPrices.push({
          skuId: s.skuId,
          currencyId: currency.id,
          price: price,
        });
      });
    setGames(newGames);
    saveDirtyPrices(dirtyPrices);
  };

  let _cellRenderer = ({ columnIndex, t, key, parent, rowIndex, style }) => {
    let content = <div></div>;

    if (rowIndex === 0) {
      // header
      if (columnIndex === 0) {
        content = (
          <GameNameHeaderCell
            filterGame={filterGame}
            handleFilterGame={handleFilterGame}
          />
        );
      } else if (columnIndex === 1) {
        // package id
        content = (
          <PackageIdHeader
            filterAppPackageId={filterAppPackageId}
            handleFilterAppPackageId={handleFilterAppPackageId}
          />
        );
      } else if (columnIndex > 1) {
        content = (
          <CurrencyHeaderCell currency={currencies[columnIndex - fixedCols]} />
        );
      }
    } else if (games && currencies) {
      // game
      let game = games[rowIndex - 1];
      if (game) {
        if (!game.isSKU) {
          if (columnIndex === 0) {
            content = <GameNameCell game={game} />;
          } else if (columnIndex === 1) {
            content = <GameAppIdCell game={game} />;
          } else {
            content = (
              <GameCurrencyNameCell
                currency={currencies[columnIndex - fixedCols]}
                onChange={bulkChange}
                editable={true}
                game={game}
                locked={locked}
                className={`grid-t-${columnIndex}-${rowIndex}`}
                onCursor={(key) =>
                  cursorNavigate({ key, rowIndex, columnIndex })
                }
              />
            );
          }
        } else {
          let currency = currencies[columnIndex - fixedCols];

          if (columnIndex === 0) {
            content = <SKUNameCell sku={game} />;
          } else if (columnIndex === 1) {
            content = <PackageIdCell packageId={game.packageId} />;
          } else if (columnIndex > 1 && currency) {
            let skuPrice = game.currencies.find((x) => x.id === currency.id);
            let skuDraftPrice = game.draftCurrencies.find(
              (x) => x.id === currency.id
            );
            content = (
              <PriceCell
                currency={currency}
                skuPrice={skuPrice?.price}
                skuDraftPrice={skuDraftPrice?.price}
                game={game}
                editable={true}
                saving={skuDraftPrice?.saving}
                showDraft={showDraft}
                onChange={onChange}
                locked={locked}
                className={`grid-t-${columnIndex}-${rowIndex}`}
                onCursor={(key) =>
                  cursorNavigate({ key, rowIndex, columnIndex })
                }
              />
            );
          } else {
            content = <EmptyCell />;
          }
        }
      } else {
        content = <EmptyCell />;
      }
    }

    return (
      // <CellMeasurer
      //   cache={_cache}
      //   columnIndex={columnIndex}
      //   key={key}
      //   parent={parent}
      //   rowIndex={rowIndex}
      // >
      <div key={key} style={{ ...style, overflow: 'hidden' }}>
        {content}
      </div>
      //  </CellMeasurer>
    );
  };

  let _getColumnWidth = ({ index }) => {
    switch (index) {
      case 0:
        return 330;
      case 1:
        return 100;
      default:
        return 150;
    }
  };

  let _getRowHeight = ({ index }) => {
    if (index === 0) {
      return 120;
    }
    return 39;
  };

  return currencies && games ? (
    <>
      <ActionBar
        breadcrumb={[
          {
            link: '/pricing/grid',
            label: t('Pricing'),
          },
        ]}
      >
        <div>
          <ResponsiveActionBarButtons
            buttons={[
              <Button
                key="deleteAllDrafts"
                className="btn btn-danger ms-2 d-none d-md-inline-flex"
                onClick={() => setDeleteAllDraftsSlideoutOpen(true)}
                text={t('Delete all draft prices')}
                isDisabled={!hasDraftPrices || locked}
              />,
              <Button
                key="exportDraft"
                className="btn btn-default ms-2 d-none d-md-inline-flex"
                onClick={handleExportDraft}
                text={t('Export draft prices')}
                isDisabled={locked}
              />,
              <Button
                key="exportLLive"
                className="btn btn-default ms-2 d-none d-md-inline-flex"
                onClick={handleExportLive}
                text={t('Export live prices')}
                isDisabled={locked}
              />,
              <Button
                key="copyToLive"
                className="btn btn-primary ms-2 d-none d-md-inline-flex"
                onClick={handleCopyToLive}
                text={t('Copy draft to live')}
                isDisabled={!hasDraftPrices || locked}
              />,
            ]}
          />
        </div>
      </ActionBar>
      {/* need to make this fill the screen */}
      <div className="grid-wrapper pricing-grid">
        <AutoSizer>
          {({ width, height }) => (
            <MultiGrid
              {...gridState}
              fixedColumnCount={fixedCols}
              fixedRowCount={1}
              cellRenderer={_cellRenderer}
              columnWidth={_getColumnWidth}
              columnCount={currencies.length + fixedCols}
              enableFixedColumnScroll
              enableFixedRowScroll
              height={height}
              rowHeight={_getRowHeight}
              //deferredMeasurementCache={_cache}
              rowCount={games.length + 1}
              style={STYLE}
              styleBottomLeftGrid={STYLE_BOTTOM_LEFT_GRID}
              styleTopLeftGrid={STYLE_TOP_LEFT_GRID}
              styleTopRightGrid={STYLE_TOP_RIGHT_GRID}
              width={width}
              hideTopRightGridScrollbar
              hideBottomLeftGridScrollbar
            />
          )}
        </AutoSizer>
      </div>
      <SlidingPane
        isOpen={deleteAllDraftsSlideoutOpen}
        hideHeader={true}
        from="right"
        className="small-side-panel"
        onRequestClose={() => setDeleteAllDraftsSlideoutOpen(false)}
      >
        <DeleteAllDrafts
          done={() => {
            setHasDraftPrices(false);
            setReload(new Date());
            setDeleteAllDraftsSlideoutOpen(false);
          }}
          cancel={() => setDeleteAllDraftsSlideoutOpen(false)}
        />
      </SlidingPane>
    </>
  ) : (
    <Loading></Loading>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    location: ownProps.location,
    setClasses: ownProps.setClasses,
    currencies: state.currencies,
  };
}

const mapDispatchToProps = {
  getAllCurrencies,
};

PricingGrid.propTypes = {
  location: PropTypes.object.isRequired,
  setClasses: PropTypes.func.isRequired,
  currencies: PropTypes.array,
  getAllCurrencies: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(PricingGrid);
