import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import UsersList from './components/UsersList';
import ActionBar from '../../layout/ActionBar';
import AddMember from './slideout/AddMember';
import { getUserProfileGroupById } from '../../../actions/userProfileGroupActions';
import { toast } from 'react-toastify';
import SlidingPane from 'react-sliding-pane';
import Button from '../../common/Button';
import { useTranslation } from 'react-i18next';
import CopyMembers from './slideout/CopyMembers';
import ResponsiveActionBarButtons from '../../common/ResponsiveActionBarButtons';
import { hasPermission } from '../../auth/authUtils';
import permissionTypes from '../../../constants/permissionTypes';

function UsersInGroup({ id, location, history, currentUser }) {
  const { t } = useTranslation();
  const [reload, setReload] = useState();
  const [userProfileGroup, setUserProfileGroup] = useState({});

  const [addSlideoutOpen, setAddSlideoutOpen] = useState(false);
  const handleAddMember = (e) => {
    e.cancelBubble = true;
    setAddSlideoutOpen(true);
    return false;
  };

  const [copySlideoutOpen, setCopySlideoutOpen] = useState(false);
  const handleCopyMembers = (e) => {
    e.cancelBubble = true;
    setCopySlideoutOpen(true);
    return false;
  };

  useEffect(() => {
    if (id) {
      getUserProfileGroupById(id)
        .then((d) => {
          setUserProfileGroup(d);
        })
        .catch((e) => {
          console.error(e);
          history.push('/settings/user-profile-groups/');
          toast.error(t('Group not found'));
        });
    }
  }, [id, history, t]);

  return (
    <>
      <ActionBar
        breadcrumb={[
          {
            link: '/settings/user-profile-groups',
            label: t('User profile groups'),
          },
          {
            link: `settings/user-profile-groups/${id}`,
            label: userProfileGroup.name,
          },
          { label: t('Members') },
        ]}
      >
        {hasPermission(currentUser, permissionTypes.MANAGE_USERGROUPS) && (
          <ResponsiveActionBarButtons
            buttons={[
              <Button
                key="copyMembers"
                className="btn btn-default ms-2 d-none d-md-inline-flex"
                onClick={handleCopyMembers}
                text={t('Copy members from another group')}
              />,
              <Button
                key="addMember"
                className="btn btn-primary ms-2"
                onClick={handleAddMember}
                text={t('Add member')}
              />,
            ]}
          />
        )}
      </ActionBar>
      <UsersList
        location={location}
        userProfileGroupById={id}
        reload={reload}
      />

      <SlidingPane
        isOpen={addSlideoutOpen}
        hideHeader={true}
        from="right"
        className="side-panel"
        onRequestClose={() => setAddSlideoutOpen(false)}
      >
        <AddMember
          userProfileGroupId={id}
          cancel={() => setAddSlideoutOpen(false)}
          done={(d) => {
            setAddSlideoutOpen(false);
            setReload(new Date());
          }}
        />
      </SlidingPane>

      <SlidingPane
        isOpen={copySlideoutOpen}
        hideHeader={true}
        from="right"
        className="side-panel"
        onRequestClose={() => setCopySlideoutOpen(false)}
      >
        <CopyMembers
          targetProfileGroupId={id}
          cancel={() => setCopySlideoutOpen(false)}
          done={(d) => {
            setCopySlideoutOpen(false);
            setReload(new Date());
          }}
        />
      </SlidingPane>
    </>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    id: ownProps.match.params.id,
    location: ownProps.location,
    history: ownProps.history,
    currentUser: state.auth,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(UsersInGroup);
