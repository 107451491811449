import { combineReducers } from 'redux';

import activation from './activationReducer';
import announcements from './announcementReducer';
import auth from './authReducer';
import basket from './basketReducer';
import bulkTransactions from './bulkTransactionReducer';
import companies from './companyReducer';
import companyTypes from './companyTypeReducer';
import connectedUsers from './connectedUsersReducer';
import disableReasons from './disableReasonReducer';
import disabledTransactions from './disabledTransactionReducer';
import gameUploadResults from './gameUploadReducer';
import gameSKUPricingUploadResults from './gameSKUPricingUploadReducer';
import games from './gameReducer';
import importTransactions from './importTransactionReducer';
import keyProviders from './keyProviderReducer';
import loader from './loaderReducer';
import moveTransactions from './moveTransactionReducer';
import navMenuOpen from './navMenuOpenReducer';
import packageTypes from './packageTypeReducer';
import platforms from './platformReducer';
import soldTransaction from './soldTransactionReducer';
import steamRequest from './steamRequestReducer';
import territories from './territoryReducer';
import currencies from './currencyReducer';
import workflows from './workflowReducer';

const rootReducer = combineReducers({
  activation,
  announcements,
  auth,
  basket,
  bulkTransactions,
  companies,
  companyTypes,
  connectedUsers,
  disableReasons,
  disabledTransactions,
  gameUploadResults,
  gameSKUPricingUploadResults,
  games,
  importTransactions,
  keyProviders,
  loader,
  moveTransactions,
  navMenuOpen,
  packageTypes,
  platforms,
  soldTransaction,
  steamRequest,
  territories,
  workflows,
  currencies,
});

export default rootReducer;
