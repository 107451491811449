import React from 'react';
import { useForm } from 'react-hook-form';
import KwPanel from '../../common/KwPanel';
import FormDate from '../../common/FormDate';
import FormInput from '../../common/FormInput';
import FormTextArea from '../../common/FormTextArea';
import FormCheckBox from '../../common/FormCheckBox';
import GameLink from '../../common/links/GameLink';
import { CommaNumber_NoDecimal } from '../../../util/formatter';

import icons from '../../../constants/icons';
import FormFileUpload from '../../common/FormFileUpload';
import { useState } from 'react';
import CompanyLink from '../../common/links/CompanyLink';
import Button from '../../common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

function CompleteForm({
  requestGroup,
  showRemove,
  handleRemove,
  handleRemoveAll,
  onSubmit,
  handleUploadAccountDetails,
  disabled,
  currentUser,
  done,
  hideConfirm,
}) {
  const { t } = useTranslation();
  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [file, setFile] = useState();
  const addFiles = (files) => {
    setFile(files[0]);
  };
  const gamesMappedToAccountDetails = requestGroup.games
    .map((a) => a.accountDetails)
    .reduce((a, b) => a + b);

  const gamesMappedToKeysRequested = requestGroup.games
    .map((a) => a.numberOfKeys)
    .reduce((a, b) => a + b);

  return (
    <KwPanel key={requestGroup.id} className="space-bottom">
      <header className="mb-2">
        <div>
          <h2 className="no-margin bold d-flex">
            <span className="me-3">
              <CompanyLink
                onClick={done}
                id={requestGroup.companyId}
                name={requestGroup.companyName}
              />
            </span>
            -<span className="ms-3">{requestGroup.keyWorkflowName}</span>
          </h2>
          {requestGroup.keyWorkflowDescription && (
            <p className="mt-3">{requestGroup.keyWorkflowDescription}</p>
          )}
        </div>
      </header>
      <div className="table-wrapper">
        <table className="table table-has-buttons jsDataTable width-100 space-bottom td-align-top">
          <thead>
            <tr>
              <th scope="col" width="40%">
                <span>{t('Game')}</span>
              </th>
              <th scope="col">
                <span>{t('Platform')}</span>
              </th>
              <th scope="col">
                <span>{t('Provider')}</span>
              </th>
              <th scope="col">
                <span>{t('Package Id')}</span>
              </th>
              {requestGroup.hasRequesterTags && (
                <th scope="col">
                  <span>{t('Tag')}</span>
                </th>
              )}
              <th scope="col" className="text-right">
                <span>{t('Keys')}</span>
              </th>
              {requestGroup.endUserDetailsRequired && (
                <th scope="col" className="text-right">
                  <span>{t('Account details')}</span>
                </th>
              )}
              <th scope="col" style={{ minWidth: '68px', width: '68px' }}>
                <span className="sr-only">{t('Row actions')}</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {requestGroup.games.map((game) => (
              <tr key={game.requestId} className="br_row">
                <td width="40%">
                  <GameLink
                    gameId={game.id}
                    skuId={game.skuId}
                    gameTitle={game.gameTitle}
                    skuName={game.skuName}
                    currentUser={currentUser}
                    onClick={done}
                  ></GameLink>
                </td>
                <td>{game.platform}</td>
                <td>{game.keyProvider}</td>
                <td>{game.packageId}</td>
                {game.requesterTag && <td>{game.requesterTag}</td>}
                <td className="text-right num">
                  {CommaNumber_NoDecimal(game.numberOfKeys)}
                </td>
                {requestGroup.endUserDetailsRequired && (
                  <td
                    className="text-right"
                    onClick={() =>
                      handleUploadAccountDetails(
                        game.requestId,
                        game.numberOfKeys,
                        true
                      )
                    }
                    title={t(
                      'Add or edit the account details for this request'
                    )}
                  >
                    {game.accountDetails !== game.numberOfKeys && (
                      <FontAwesomeIcon
                        icon={icons.ARE_YOU_SURE}
                        className={`me-3 text-orange`}
                        title={t('Are you sure?')}
                      />
                    )}
                    <span className="a">
                      {t('{accountDetails} of {numberOfKeys}', {
                        accountDetails: game.accountDetails,
                        numberOfKeys: game.numberOfKeys,
                      })}
                    </span>
                  </td>
                )}
                <td
                  className="text-right"
                  style={{ minWidth: '68px', width: '68px' }}
                >
                  <Button
                    className="btn btn-default btn-sm btn-xs-full show-loading"
                    isDisabled={disabled || false}
                    onClick={() =>
                      handleRemove(requestGroup.id, game.requestId)
                    }
                    style={{
                      display: showRemove ? 'block' : 'none',
                    }}
                    text={t('Remove')}
                    icon={icons.DELETE}
                    hideTitle={true}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="">
            {requestGroup.poRequired && (
              <FormInput
                register={register}
                name="poNumber"
                label={t('PO number')}
                defaultValue={requestGroup.poNumber || ''}
                disabled={disabled || false}
              />
            )}
            {requestGroup.justificationRequired && (
              <FormTextArea
                register={register}
                required={true}
                name="justification"
                label={t('Justification')}
                defaultValue={requestGroup.justification || ''}
                disabled={disabled || false}
              />
            )}
            {requestGroup.showRequiredByDate && (
              <FormDate
                register={register}
                name="requiredByDate"
                label={t('Required by date')}
                placeholder={t('Optional')}
                defaultValue={requestGroup.requiredByDate || ''}
                disabled={disabled || false}
              />
            )}
            {requestGroup.requiresTermsAgreement && (
              <FormCheckBox
                register={register}
                setValue={setValue}
                getValues={getValues}
                required={true}
                name="termsAgreed"
                label={t('Accept terms')}
                disabled={disabled || false}
                description={t(
                  'By ticking this box and clicking the button, I agree and consent to the terms of this system as covered by the processes and procedures set out by the organisation.'
                )}
              ></FormCheckBox>
            )}
            {(requestGroup.fileUploadMandatory ||
              requestGroup.fileUploadOptional) && (
              <FormFileUpload
                label={t('Purchase order')}
                fileExtension=".pdf"
                error={errors.files}
                required={requestGroup.fileUploadMandatory}
                addFiles={addFiles}
                removeFile={() => setFile(null)}
                files={file ? [file] : []}
                disabled={disabled || false}
              ></FormFileUpload>
            )}
            {requestGroup.endUserDetailsRequired && (
              <div className="alert alert-warning twinkle flex-shrink-0 ">
                <div>
                  {t(
                    'This workflow requires account details to be uploaded. You have currently uploaded {gamesMappedToAccountDetails} account details for the {gamesMappedToKeysRequested} keys requested',
                    {
                      gamesMappedToAccountDetails: gamesMappedToAccountDetails,
                      gamesMappedToKeysRequested: gamesMappedToKeysRequested,
                    }
                  )}
                </div>
              </div>
            )}
            <div className="float-right-sm-up">
              {showRemove && (
                <Button
                  className="btn btn-default btn-xs-full space-bottom-xs show-loading me-2"
                  onClick={() => handleRemoveAll(requestGroup.id)}
                  isDisabled={disabled || false}
                  text={t('Remove all')}
                />
              )}
              {!showRemove && !hideConfirm && (
                <Button
                  className="btn btn-primary btn-xs-full space-left-sm show-loading"
                  onClick={handleSubmit((data) =>
                    onSubmit(requestGroup.id, {
                      ...requestGroup,
                      ...data,
                      file,
                    })
                  )}
                  isDisabled={disabled || false}
                  text={t('Confirm request')}
                  icon={icons.CONFIRM_REQUEST}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </KwPanel>
  );
}
export default CompleteForm;
