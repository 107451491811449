import React from 'react';
import { Route, Switch } from 'react-router';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Home from './leftmenus/Home';
import Game from './leftmenus/Game';
import Company from './leftmenus/Company';
import User from './leftmenus/User';
import Keys from './leftmenus/Keys';
import Promotion from './leftmenus/Promotion';
import Pricing from './leftmenus/Pricing';
import Reports from './leftmenus/Reports';
import Requests from './leftmenus/Requests';
import Settings from './leftmenus/Settings';
import LeftNavWrapper from './menu-components/LeftNavWrapper';
import LeftMenuItem from './menu-components/LeftMenuItem';
import userTypes from '../../constants/userTypes';
import icons from '../../constants/icons';
import { CurrentDateAsString } from '../../util/formatter';
import { useTranslation } from 'react-i18next';

function LeftMenu({ currentUser, companyTypes }) {
  const { t } = useTranslation();
  const version = `v${window.globalConfig?.version || CurrentDateAsString()}`;
  return (
    <div id="panelFlyout" className="panel-flyout">
      <div className="panel-flyout__inner">
        <div id="scroll">
          <nav className="hide-phablet" role="navigation">
            <Switch>
              <Route path="/settings">
                <Settings currentUser={currentUser}></Settings>
              </Route>
              <Route>
                <>
                  <Route exact path="/">
                    <Home
                      currentUser={currentUser}
                      companyTypes={companyTypes}
                    ></Home>
                  </Route>
                  <Route path="/games">
                    <Game currentUser={currentUser}></Game>
                  </Route>
                  <Route path="/game">
                    <Game currentUser={currentUser}></Game>
                  </Route>
                  <Route path="/sku">
                    <Game currentUser={currentUser}></Game>
                  </Route>
                  <Route path="/companies">
                    <Company
                      currentUser={currentUser}
                      companyTypes={companyTypes}
                    ></Company>
                  </Route>
                  <Route path="/company">
                    <Company
                      currentUser={currentUser}
                      companyTypes={companyTypes}
                    ></Company>
                  </Route>
                  <Route path="/users">
                    <User
                      currentUser={currentUser}
                      companyTypes={companyTypes}
                    ></User>
                  </Route>
                  <Route path="/user">
                    <User
                      currentUser={currentUser}
                      companyTypes={companyTypes}
                    ></User>
                  </Route>
                  <Route path="/keys">
                    <Keys currentUser={currentUser}></Keys>
                  </Route>
                  <Route path="/requests">
                    <Requests currentUser={currentUser}></Requests>
                  </Route>
                  <Route path="/reports">
                    <Reports currentUser={currentUser}></Reports>
                  </Route>
                  <Route path="/request">
                    <Requests currentUser={currentUser}></Requests>
                  </Route>

                  <Route path="/promotions">
                    <Promotion currentUser={currentUser}></Promotion>
                  </Route>
                  <Route path="/promotion">
                    <Promotion currentUser={currentUser}></Promotion>
                  </Route>

                  <Route path="/pricing">
                    <Pricing currentUser={currentUser}></Pricing>
                  </Route>

                  <Route path="/settings"></Route>
                  <LeftNavWrapper>
                    <LeftMenuItem
                      url="/settings"
                      icon={icons.SETTINGS}
                      title={t('Settings')}
                      allowedUserTypes={[userTypes.ADMIN]}
                      currentUser={currentUser}
                      spinOnOver={true}
                    />
                  </LeftNavWrapper>
                </>
              </Route>
            </Switch>
          </nav>
          <div className="brandInfo">
            <span>&copy; 2017-2025</span>
            <a
              href="https://K3YS.co/"
              target="_blank"
              rel="noopener noreferrer"
              className="ms-2"
            >
              K3YS.co
            </a>
            <span>{version}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

LeftMenu.propTypes = {
  currentUser: PropTypes.object,
};
export default withRouter(LeftMenu);
