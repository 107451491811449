import React from 'react';
import PropTypes from 'prop-types';
import PriceCellEditable from './PriceCellEditable';
import PriceCellReadOnly from './PriceCellReadOnly';

function PriceCell({
  currency,
  skuPrice,
  skuDraftPrice,
  game,
  editable,
  showDraft,
  onChange,
  saving,
  locked,
  className,
  onCursor,
}) {
  return editable ? (
    <PriceCellEditable
      currency={currency}
      skuPrice={skuPrice}
      skuDraftPrice={skuDraftPrice}
      game={game}
      showDraft={showDraft}
      onChange={onChange}
      saving={saving}
      locked={locked}
      className={className}
      onCursor={onCursor}
    />
  ) : (
    <PriceCellReadOnly
      currency={currency}
      skuPrice={skuPrice}
      skuDraftPrice={skuDraftPrice}
      game={game}
      showDraft={showDraft}
    />
  );
}

PriceCell.propTypes = {
  currency: PropTypes.object,
  skuPrice: PropTypes.number,
  skuDraftPrice: PropTypes.number,
  game: PropTypes.object,
  editable: PropTypes.bool,
  showDraft: PropTypes.bool,
  saving: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  locked: PropTypes.bool,
  className: PropTypes.string,
  onCursor: PropTypes.func,
};

export default PriceCell;
