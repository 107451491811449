import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import FormToggleBool from '../../../common/FormToggleBool';
import SlideoutLayout from '../../../layout/slideout/SlideoutLayout';
import permissionTypes from '../../../../constants/permissionTypes';
import KwPanel from '../../../common/KwPanel';
import { hasFeature } from '../../../auth/authUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import icons from '../../../../constants/icons';

function AddPermission({ permissionKeys, groups, done, cancel, currentUser }) {
  const { handleSubmit, control } = useForm();
  const { t } = useTranslation();

  const onSubmit = (data) => {
    let selected = [];
    // selected keys
    Object.keys(data).forEach((x) => {
      if (data[x]) selected.push(x);
    });

    // added
    let added = selected.filter((item) => !permissionKeys.includes(item));

    // removed
    let removed = permissionKeys.filter((item) => !selected.includes(item));

    done({ added, removed });
    return;
  };

  return (
    <SlideoutLayout
      title={t('Add permission')}
      cancel={cancel}
      done={done}
      bar={
        <input
          type="submit"
          value={t('Save')}
          className="btn btn-primary"
          onClick={handleSubmit(onSubmit)}
        />
      }
    >
      <KwPanel className="mb-4 pb-0">
        <header className="mb-3">
          <h2 className="mb-0">
            <FontAwesomeIcon icon={icons.KEYS} className="c-blue mx-3" />
            {t('Keys')}
          </h2>
        </header>
        <FormToggleBool
          label={t('Manage keys')}
          description={t('Full management of the key lifecycle')}
          name={permissionTypes.MANAGE_KEYS}
          control={control}
          defaultValue={permissionKeys.includes(permissionTypes.MANAGE_KEYS)}
        />
        <FormToggleBool
          label={t('Upload activations')}
          description={t('Upload activated keys')}
          name={permissionTypes.UPLOAD_ACTIVATIONS}
          control={control}
          defaultValue={permissionKeys.includes('UPLOAD_ACTIVATIONS')}
        />
        <FormToggleBool
          label={t('Search keys')}
          description={t('Search for keys in Alaska by full key code or tag')}
          name={permissionTypes.KEYS_SEARCH}
          control={control}
          defaultValue={permissionKeys.includes(permissionTypes.KEYS_SEARCH)}
        />
      </KwPanel>
      <KwPanel className="mb-4 pb-0">
        <header className="mb-3">
          <h2 className="mb-0">
            <FontAwesomeIcon icon={icons.USERS} className="c-blue mx-3" />
            {t('Users')}
          </h2>
        </header>
        <FormToggleBool
          label={t('Request on behalf of another user')}
          description={t(
            'Create a key request on behalf of someone else in any company'
          )}
          name={permissionTypes.REQUEST_ON_BEHALF_OF}
          control={control}
          defaultValue={permissionKeys.includes(
            permissionTypes.REQUEST_ON_BEHALF_OF
          )}
        />
        <FormToggleBool
          label={t('Manage user groups')}
          description={t('Manage list of available user groups.')}
          name={permissionTypes.MANAGE_USERGROUPS}
          control={control}
          defaultValue={permissionKeys.includes(
            permissionTypes.MANAGE_USERGROUPS
          )}
        />
        <FormToggleBool
          label={t('Manage permissions')}
          description={t('Manage permissions (this screen)')}
          name={permissionTypes.MANAGE_PERMISSIONS}
          control={control}
          defaultValue={permissionKeys.includes(
            permissionTypes.MANAGE_PERMISSIONS
          )}
        />
        <FormToggleBool
          label={t('Manage users')}
          description={t('Invite / Delete / Edit all users of the system.')}
          name={permissionTypes.MANAGE_USERS}
          control={control}
          defaultValue={permissionKeys.includes(permissionTypes.MANAGE_USERS)}
        />
        <FormToggleBool
          label={t('Impersonate users')}
          description={t('Login as any user, see what they see, act as them.')}
          name={permissionTypes.IMPERSONATE_USERS}
          control={control}
          defaultValue={permissionKeys.includes(
            permissionTypes.IMPERSONATE_USERS
          )}
        />
      </KwPanel>
      <KwPanel className="mb-4 pb-0">
        <header className="mb-3">
          <h2 className="mb-0">
            <FontAwesomeIcon icon={icons.GRID} className="c-blue mx-3" />
            {t('Games')}
          </h2>
        </header>
        <FormToggleBool
          label={t('Manage games')}
          description=""
          name={permissionTypes.MANAGE_GAMES}
          control={control}
          defaultValue={permissionKeys.includes(permissionTypes.MANAGE_GAMES)}
        />
        <FormToggleBool
          label={t('Unarchive games')}
          description=""
          name={permissionTypes.UNARCHIVE_GAMES}
          control={control}
          defaultValue={permissionKeys.includes(
            permissionTypes.UNARCHIVE_GAMES
          )}
        />
      </KwPanel>
      <KwPanel className="mb-4 pb-0">
        <header className="mb-3">
          <h2 className="mb-0">
            <FontAwesomeIcon icon={icons.GRID} className="c-blue mx-3" />
            {t('Reports')}
          </h2>
        </header>
        <FormToggleBool
          label={t('View reports')}
          description=""
          name={permissionTypes.VIEW_REPORTS}
          control={control}
          defaultValue={permissionKeys.includes(permissionTypes.VIEW_REPORTS)}
        />
      </KwPanel>
      <KwPanel className="mb-4 pb-0">
        <header className="mb-3">
          <h2 className="mb-0">
            <FontAwesomeIcon icon={icons.SETTINGS} className="c-blue mx-3" />
            {t('Settings')}
          </h2>
        </header>
        <FormToggleBool
          label={t('Manage companies')}
          description=""
          name={permissionTypes.MANAGE_COMPANIES}
          control={control}
          defaultValue={permissionKeys.includes(
            permissionTypes.MANAGE_COMPANIES
          )}
        />
        <FormToggleBool
          label={t('Manage platforms')}
          description=""
          name={permissionTypes.MANAGE_PLATFORMS}
          control={control}
          defaultValue={permissionKeys.includes(
            permissionTypes.MANAGE_PLATFORMS
          )}
        />
        {hasFeature(currentUser, 'Promotions') && (
          <FormToggleBool
            label={t('Manage promotions')}
            description=""
            name={permissionTypes.MANAGE_PROMOTIONS}
            control={control}
            defaultValue={permissionKeys.includes(
              permissionTypes.MANAGE_PROMOTIONS
            )}
          />
        )}
        {hasFeature(currentUser, 'Pricing') && (
          <FormToggleBool
            label={t('Manage pricing')}
            description=""
            name={permissionTypes.MANAGE_PRICING}
            control={control}
            defaultValue={permissionKeys.includes(
              permissionTypes.MANAGE_PRICING
            )}
          />
        )}
        <FormToggleBool
          label={t('Manage settings')}
          description=""
          name={permissionTypes.MANAGE_SETTINGS}
          control={control}
          defaultValue={permissionKeys.includes(
            permissionTypes.MANAGE_SETTINGS
          )}
        />
        <FormToggleBool
          label={t('Manage territories')}
          description=""
          name={permissionTypes.MANAGE_TERRITORIES}
          control={control}
          defaultValue={permissionKeys.includes(
            permissionTypes.MANAGE_TERRITORIES
          )}
        />
        <FormToggleBool
          label={t('Manage workflows')}
          description=""
          name={permissionTypes.MANAGE_WORKFLOWS}
          control={control}
          defaultValue={permissionKeys.includes(
            permissionTypes.MANAGE_WORKFLOWS
          )}
        />
      </KwPanel>
    </SlideoutLayout>
  );
}

AddPermission.propTypes = {
  cancel: PropTypes.func.isRequired,
  done: PropTypes.func.isRequired,
};
export default AddPermission;
