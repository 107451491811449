import React, { useState } from 'react';
import { connect } from 'react-redux';

import { loadSkus } from '../../../actions/gameActions';
import { NavLink } from 'react-router-dom';
import {
  Table,
  SimpleTextFilter,
  getTableSettingsDefaults,
} from '../../common/Table';
import Loading from '../../common/Loading';
import KwPanel from '../../common/KwPanel';
import AutoSelectText from '../../common/AutoSelectText';
import useTableSettings from '../../../hooks/useTableSettings';
import Checkbox from '../../common/Checkbox';
import {
  hasAnyPermissionForCompany,
  hasPermission,
  hasPermissionForCompany,
  userIs,
} from '../../auth/authUtils';
import permissionTypes from '../../../constants/permissionTypes';
import userTypes from '../../../constants/userTypes';
import KebabMenu from '../../common/KebabMenu';
import Button from '../../common/Button';
import icons from '../../../constants/icons';
import SkuRestrictions from '../../sku/components/SkuRestrictions';
import TerritoryLink from '../../common/links/TerritoryLink';
import WorkflowLink from '../../common/links/WorkflowLink';
import PlatformLink from '../../common/links/PlatformLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import NoAppId from '../../common/NoAppId';
import NoProductCode from '../../common/NoProductCode';
import NoPackageId from '../../common/NoPackageId';

function SkuList({
  location,
  reload,
  canRequestKeys,
  onRequestKeys,
  gameId,
  publisherCompanyId,
  onAddSku,
  canUploadKeys,
  handleSteamBulkRequest,
  locked,
  currentUser,
  handleBulkActions,
  itemsChecked,
  setItemsChecked,
}) {
  const { t } = useTranslation();
  let allColumns = [
    'name',
    'platform',
    'steamRef',
    'appId',
    'packageId',
    'packageType',
    'canRequestFromDate',
    'canRequestUntilDate',
    'territories',
    'isArchived',
  ];
  if (
    hasPermission(currentUser, permissionTypes.MANAGE_GAMES) ||
    hasPermission(currentUser, permissionTypes.MANAGE_KEYS)
  ) {
    allColumns.push('keyWorkflows');
  }

  const suffixColumns = [];
  if (canRequestKeys || canUploadKeys) {
    suffixColumns.push('buttons-checkbox');
  }

  const defaultVisibleColumns = [
    'name',
    'platform',
    'steamRef',
    'appId',
    'packageId',
    'packageType',
    'territories',
    'isArchived',
  ];
  const [skus, setSKUs] = useState();
  const params = new URLSearchParams(location.search);
  const { tableSettings, setTableSettings, filters, filterChange, isLoading } =
    useTableSettings(
      'skulist-settings',
      getTableSettingsDefaults({ defaultSort: 'name', defaultVisibleColumns }),
      { gameId },
      loadSkus,
      setSKUs,
      reload,
      t('Failed to load skus'),
      location
    );

  const renderAppId = (item) => {
    if (item.skuAppId) return <AutoSelectText>{item.skuAppId}</AutoSelectText>;
    else if (item.appId)
      return (
        <AutoSelectText
          title={t('This app Id is taken from the game, click to select')}
        >
          {item.appId}
        </AutoSelectText>
      );
    else return <NoAppId />;
  };

  // The base config of the columns, this will be filtered based on the visible (above)
  let columnsConfig = [
    {
      Key: 'name',
      Label: t('Name'),
      className: 'nowrap',
      Render: (item) =>
        hasAnyPermissionForCompany(
          currentUser,
          [permissionTypes.MANAGE_GAMES, permissionTypes.MANAGE_KEYS],
          item.publisherCompanyId
        ) ? (
          <>
            <NavLink to={`/sku/${item.id}`}>{item.name}</NavLink>
            {item.isArchived && (
              <span className="badge bg-danger ms-3">
                <FontAwesomeIcon icon={icons.ARCHIVED} />
                {t('Archived')}
              </span>
            )}
          </>
        ) : (
          item.name
        ),
      Filter: {
        Render: (col) =>
          hasPermissionForCompany(
            currentUser,
            permissionTypes.MANAGE_GAMES,
            col.publisherCompanyId
          ) ? (
            <div className="row">
              <div className="col-md-8">
                <SimpleTextFilter
                  Key={col.Key}
                  Value={params.get(`f_${[col.Key]}`) || ''}
                  onChange={(value) => filterChange(col.Key, value)}
                  placeholder={t('name...')}
                />
              </div>
              <div className="col-md-4">
                <select
                  className="form-control"
                  value={params.get(`f_isArchived`) || ''}
                  onChange={(el) => filterChange('isArchived', el.target.value)}
                >
                  <option value="">{t('All')}</option>
                  <option value="Live">{t('Live')}</option>
                  <option value="Archived">{t('Archived')}</option>
                </select>
              </div>
            </div>
          ) : (
            <SimpleTextFilter
              Key={col.Key}
              Value={params.get(`f_${[col.Key]}`) || ''}
              onChange={(value) => filterChange(col.Key, value)}
              placeholder={t('name...')}
            />
          ),
      },
    },
    {
      Key: 'platform',
      Label: t('Platform'),
      Render: (item) => (
        <PlatformLink id={item.platformId} name={item.platform}></PlatformLink>
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
            placeholder={t('platform...')}
          />
        ),
      },
    },
    {
      Key: 'steamRef',
      Label: t('Product code'),
      className: 'autoselect',
      Render: (item) =>
        item.steamRef ? (
          <AutoSelectText>{item.steamRef}</AutoSelectText>
        ) : (
          <NoProductCode />
        ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
            placeholder={t('Code...')}
          />
        ),
      },
    },
    {
      Key: 'appId',
      Label: t('App Id'),
      className: 'autoselect',
      Render: renderAppId,
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
            placeholder={t('Id...')}
          />
        ),
      },
    },
    {
      Key: 'packageId',
      Label: t('Package Id'),
      className: 'autoselect',
      Render: (item) =>
        item.packageId ? (
          <AutoSelectText>{item.packageId}</AutoSelectText>
        ) : (
          <NoPackageId />
        ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
            placeholder={t('Id...')}
          />
        ),
      },
    },
    {
      Key: 'packageType',
      Label: t('Package type'),
      Render: (item) => item.packageType,
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
            placeholder={t('type...')}
          />
        ),
      },
    },
    {
      Key: 'keyWorkflows',
      Label: t('Workflows'),
      Render: (item) => (
        <div className="restrictions">
          <SkuRestrictions
            isDisallow={item.disallowListedKeyWorkflows}
            restrictions={item.keyWorkflows}
            noDataText={t('No restrictions')}
            render={({ id, name, colour }) => (
              <WorkflowLink
                id={id}
                key={id}
                name={name}
                colour={colour}
              ></WorkflowLink>
            )}
          ></SkuRestrictions>
        </div>
      ),
    },
    {
      Key: 'territories',
      Label: t('Territories'),
      Render: (item) => (
        <div className="restrictions">
          <SkuRestrictions
            isDisallow={item.disallowListedTerritories}
            restrictions={item.territories}
            noDataText={t('No restrictions')}
            render={({ id, name, colour }) => (
              <TerritoryLink
                id={id}
                key={id}
                name={name}
                colour={colour}
              ></TerritoryLink>
            )}
          ></SkuRestrictions>
        </div>
      ),
    },
    {
      Key: 'canRequestFromDate',
      Label: t('Can request from'),
      Render: (item) => item.canRequestFromDate,
    },
    {
      Key: 'canRequestUntilDate',
      Label: t('Can request until'),
      Render: (item) => item.canRequestUntilDate,
    },
    {
      Key: 'buttons-checkbox',
      Label: '',
      className: 'text-right no-navigate',
      Sort: false,
      width: '2%',
      Filter: {
        Render: (col) =>
          !locked && (
            <Checkbox
              disabled={!skus}
              checked={false}
              onChange={(checked) =>
                checked
                  ? setItemsChecked(skus?.data?.filter((x) => x.canUploadKeys))
                  : setItemsChecked([])
              }
            ></Checkbox>
          ),
      },
      Render: (item) =>
        !locked && (
          <div className="d-flex align-items-center justify-content-end">
            <KebabMenu
              take={1}
              className="inline-icons"
              buttons={[
                item.canRequestKeys && (
                  <Button
                    className="btn btn-default me-2"
                    onClick={(e) => {
                      onRequestKeys(e, item);
                    }}
                    icon={icons.REQUEST_KEYS}
                    text={t('Request keys')}
                  ></Button>
                ),
                item.canUploadKeys && (
                  <Button
                    className="btn btn-default me-2"
                    onClick={(e) => handleSteamBulkRequest(e, [item])}
                    icon={icons.REQUEST_ON_STEAM}
                    text={t('Request on Steam')}
                  />
                ),
              ]}
            ></KebabMenu>

            {item.canUploadKeys && (
              <Checkbox
                className="ms-3"
                checked={
                  itemsChecked && itemsChecked.some((x) => x.id === item.id)
                }
                onChange={(checked) =>
                  checked
                    ? setItemsChecked([...itemsChecked, item])
                    : setItemsChecked(
                        itemsChecked.filter((x) => x.id !== item.id)
                      )
                }
              ></Checkbox>
            )}
          </div>
        ),
    },
    {
      Key: 'isArchived',
      Label: t('Status'),
      width: '12%',
      Render: (item) =>
        item.isArchived && item.isLicenseExpired ? (
          <span className="badge bg-danger">
            <FontAwesomeIcon icon={icons.ARCHIVED} className="me-2" />
            {t('Archived')}
          </span>
        ) : item.isArchived ? (
          <span className="badge bg-danger">
            <FontAwesomeIcon icon={icons.ARCHIVED} className="me-2" />
            {t('Archived')}
          </span>
        ) : item.isLicenseExpired ? (
          <span className="badge bg-pink">
            <FontAwesomeIcon
              icon={icons.SKU_LICENSE_EXPIRED}
              className="me-2"
            />
            {t('Expired')}
          </span>
        ) : (
          <span className="badge bg-success">{t('Live')}</span>
        ),
      Filter: {
        Render: (col) => (
          <select
            className={`form-control ${
              params.get(`f_${[col.Key]}`) ? 'filtered' : ''
            }`}
            value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(el) => filterChange(col.Key, el.target.value)}
          >
            <option value="">{t('All')}</option>
            <option value="Live">{t('Live')}</option>
            <option value="Archived">{t('Archived')}</option>
            <option value="Expired">{t('Expired')}</option>
          </select>
        ),
      },
    },
  ];

  // filter
  const visibleColumns = tableSettings.columns ?? defaultVisibleColumns;
  const suffixColumnsWithConfig = suffixColumns
    .map((x) => columnsConfig.find((y) => y.Key === x))
    .filter((x) => x);
  const columns = visibleColumns
    .map((x) => columnsConfig.find((y) => y.Key === x))
    .filter((x) => x);

  // return an alaska table
  return skus ? (
    <KwPanel className="mb-4">
      <header className="mb-2">
        <h2 className="no-margin">{t('Game SKUs')}</h2>
        {!locked && (
          <div>
            {hasPermissionForCompany(
              currentUser,
              permissionTypes.MANAGE_GAMES,
              publisherCompanyId
            ) && (
              <Button
                className="btn btn-primary me-2"
                onClick={onAddSku}
                text={t('Add SKU')}
              />
            )}
          </div>
        )}
      </header>
      <Table
        columns={columns}
        suffixColumns={suffixColumnsWithConfig}
        allColumns={allColumns}
        rows={skus.data.map((x) => {
          return { Key: x.id, ...x };
        })}
        totalRowCount={skus.totalRowCount}
        filteredRowCount={skus.filteredRowCount}
        tableSettings={tableSettings}
        setTableSettings={setTableSettings}
        isLoading={isLoading}
        filterChange={filterChange}
        setBulkActionsSlideoutOpen={
          userIs(currentUser, userTypes.UPLOADER) && handleBulkActions
        }
        itemsChecked={itemsChecked}
        bulkActionTitle={
          itemsChecked?.length === 0
            ? t('Bulk actions - first tick some skus')
            : t('Bulk actions')
        }
      />
    </KwPanel>
  ) : (
    <Loading></Loading>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.auth,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SkuList);
