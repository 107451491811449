import * as gameApi from '../api/gameApi';
import actionTypes from '../constants/actionTypes';

export function saveGame(game) {
  return function (dispatch) {
    return gameApi.saveGame(game).then((savedGame) => {
      dispatch({
        type: game.id ? actionTypes.UPDATE_GAME : actionTypes.CREATE_GAME,
        game: savedGame,
      });
    });
  };
}

export function loadSkus({
  gameId,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return gameApi.getSkus(
    gameId,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function getGameById(id) {
  return gameApi.getGameById(id);
}

export function loadGames({ filters, _scopes, _sort, _order, _page, _limit }) {
  return gameApi.getGames(filters, _scopes, _sort, _order, _page, _limit);
}

export function getStats(id) {
  return gameApi.getStats(id);
}

export function getStatsByDay(id) {
  return gameApi.getStatsByDay(id);
}

export function getAggregateStats(id, from, to) {
  return gameApi.getAggregateStats(id, from, to);
}

export function getAudit(id) {
  return gameApi.getAudit(id);
}

export function addAudit(id, comment) {
  return gameApi.addAudit(id, comment);
}

export function getAllGames() {
  return function (dispatch) {
    return gameApi.getAllGames().then((games) => {
      dispatch({
        type: actionTypes.LOAD_GAMES,
        games,
      });
    });
  };
}

export function archiveGame(id, lockSignOff) {
  return function (dispatch) {
    return gameApi.archiveGame(id, lockSignOff).then(() => {
      dispatch({
        type: actionTypes.ARCHIVE_GAME,
        id,
      });
    });
  };
}

export function archiveGameSku(gameId, skuId, lockSignOff) {
  return function (dispatch) {
    return gameApi.archiveGameSku(gameId, skuId, lockSignOff).then(() => {
      dispatch({
        type: actionTypes.ARCHIVE_GAMESKU,
        skuId,
      });
    });
  };
}

export function unarchiveGame(id, restorePendingRequests) {
  return function (dispatch) {
    return gameApi.unarchiveGame(id, restorePendingRequests).then(() => {
      dispatch({
        type: actionTypes.UNARCHIVE_GAME,
        id,
      });
    });
  };
}

export function unarchiveGameSku(gameId, skuId, restorePendingRequests) {
  return function (dispatch) {
    return gameApi
      .unarchiveGameSku(gameId, skuId, restorePendingRequests)
      .then(() => {
        dispatch({
          type: actionTypes.UNARCHIVE_GAME,
          skuId,
        });
      });
  };
}

export function cloneGameSku({ skuId, gameId, skuName, withRestrictions }) {
  return gameApi
    .cloneGameSku(gameId, skuId, skuName, withRestrictions)
    .then((newId) => {
      return newId;
    });
}

export function downloadGames() {
  return gameApi.downloadGames();
}

export function downloadTemplate() {
  return gameApi.downloadTemplate();
}
export function uploadGames(record) {
  return gameApi.uploadGames(record);
}

export function clearUploadResults() {
  return function (dispatch) {
    dispatch({ type: actionTypes.GAME_UPLOAD_CLEAR });
  };
}

export function exportData(filters, scopes) {
  return gameApi.exportGameListData(filters, scopes);
}
