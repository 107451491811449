import actionTypes from '../constants/actionTypes';

export default function (state = null, action) {
  switch (action.type) {
    case actionTypes.LOAD_CURRENCIES:
      return action.currencies;
    case actionTypes.UPDATE_CURRENCY:
      return state
        ? state.map((currency) =>
            currency.id === action.currency.id ? action.currency : currency
          )
        : state;
    case actionTypes.CREATE_CURRENCY:
      return state ? [...state, action.currency] : state;
    case actionTypes.DELETE_CURRENCY:
      return state
        ? state.filter((currency) => currency.id !== action.id)
        : state;
    default:
      return state;
  }
}
