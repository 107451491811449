import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { saveRequest } from '../../../actions/requestActions';
import StepWizard from 'react-step-wizard';
import KeyWorkflowTagsStep from './wizard/KeyWorkflowTagsStep';
import KeyWorkflowFreeTextTagsStep from './wizard/KeyWorkflowFreeTextTagsStep';
import SelectCompanyStep from './wizard/SelectCompanyStep';
import SelectGameStep from './wizard/SelectGameStep';
import SelectKeyWorkflowStep from './wizard/SelectKeyWorkflowStep';
import SelectSKUStep from './wizard/SelectSKUStep';
import useRequestKeysWizard from '../../../hooks/useRequestKeysWizard';
import ConfirmStep from './wizard/ConfirmStep';
import Loading from '../../common/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import WizardSteps from '../../../constants/WizardSteps';
import SlideoutLayout from '../../layout/slideout/SlideoutLayout';
import { useTranslation } from 'react-i18next';
import icons from '../../../constants/icons';

function RequestKeys({
  done,
  cancel,
  currentUser,
  gameId,
  skuId,
  saveRequest,
  handleUploadAccountDetails,
}) {
  const { t } = useTranslation();
  const [
    currentStep,
    companies,
    setCompanyId,
    keyWorkflows,
    setKeyWorkflowId,
    games,
    setGameId,
    skus,
    setSKUId,
    setRequesterTag,
    requesterTags,
    setRequesterTagId,
    keyProviderRequestInformation,
    request,
    handleBack,
    isLoading,
    setIsLoading,
    isFirstItem,
  ] = useRequestKeysWizard({
    currentUser,
    _gameId: gameId,
    _skuId: skuId,
  });
  const [wizard, setWizard] = useState();
  useEffect(() => {
    if (wizard) {
      wizard.goToNamedStep(currentStep);
    }
  }, [currentStep, wizard]);

  const handleCompanySelect = (value) => {
    setCompanyId(value);
  };

  const handleKeyWorkflowSelect = (value) => {
    setKeyWorkflowId(value);
  };

  const handleGameSelect = (value) => {
    setGameId(value);
  };

  const handleSKUSelect = (value) => {
    setSKUId(value);
  };

  const handleKeyWorkflowFreeTextTagInput = (data) => {
    setRequesterTag(data.keyWorkflowTag);
  };

  const handleKeyWorkflowTagSelect = (value) => {
    setRequesterTagId(value);
  };

  const onSubmit = (data) => {
    setIsLoading(true);
    saveRequest(data)
      .then((data) => {
        if (data.endUserDetailsRequired && handleUploadAccountDetails) {
          handleUploadAccountDetails(data.id, data.numberOfKeys);
        }

        toast.success(t('Request added to basket'));
        done();
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error?.message ?? t('Failed to add request to basket'));
      });
  };
  let custom = {
    enterRight: 'animated fadeInRight',
    enterLeft: 'animated fadeInLeft',
    exitRight: 'animated fadeOutRight',
    exitLeft: 'animated fadeOutLeft',
  };

  const isConfirmStep = currentStep === WizardSteps.CONFIRM;
  const showSteps = !isFirstItem || !isConfirmStep;
  const detailsActive = !isFirstItem && !isConfirmStep;

  return currentStep ? (
      <SlideoutLayout
        title={t('Request keys')}
        cancel={cancel}
        done={done}
        hideBar={true}
      >
        <header className="clearfix width-100">
          <div className="width-100 d-flex wizard-steps">
            {showSteps ? (
              <>
                <div
                  className={`d-flex align-items-center back-btn ${
                    isFirstItem ? 'disabled' : ''
                  }`}
                  style={{ visibility: isFirstItem ? 'hidden' : 'visible' }}
                  title={t('Back')}
                  aria-label={t('Go back')}
                  onClick={() => {
                    if (!isFirstItem) {
                      handleBack();
                    }
                  }}
                >
                  <div className="icon-wrapper">
                    <FontAwesomeIcon icon={icons.CHEVRON_LEFT} className="a" />
                  </div>
                </div>
                <div className={`step ${isFirstItem ? 'active' : ''}`}>
                  {t('Start')}
                </div>
                <div className={`step ${detailsActive ? 'active' : ''}`}>
                  {t('Details')}
                </div>
                <div
                  className={`step me-4 ${
                    !isFirstItem && isConfirmStep ? 'active' : ''
                  }`}
                >
                  {t('Basket')}
                </div>
              </>
            ) : (
              <div className={`step me-4 active`}>{t('Basket')}</div>
            )}
          </div>

          <i
            className="a fas fa-times fa-2x me-2"
            title={t('Close')}
            aria-label={t('Close panel')}
            onClick={cancel}
          ></i>
        </header>

        <div className="content">
          {currentUser && !isLoading ? (
            <StepWizard
              instance={setWizard}
              transitions={custom}
              isLazyMount={true}
            >
              <SelectCompanyStep
                stepName="company"
                currentUser={currentUser}
                companies={companies}
                handleCompanySelect={handleCompanySelect}
              ></SelectCompanyStep>
              <SelectKeyWorkflowStep
                stepName="keyWorkflow"
                keyWorkflows={keyWorkflows}
                handleKeyWorkflowSelect={handleKeyWorkflowSelect}
              ></SelectKeyWorkflowStep>
              <SelectGameStep
                stepName="game"
                games={games}
                handleGameSelect={handleGameSelect}
              ></SelectGameStep>
              <SelectSKUStep
                stepName="sku"
                skus={skus}
                handleSKUSelect={handleSKUSelect}
              ></SelectSKUStep>
              <KeyWorkflowTagsStep
                stepName="tags"
                requesterTags={requesterTags}
                handleRequesterTagSelect={handleKeyWorkflowTagSelect}
              ></KeyWorkflowTagsStep>
              <KeyWorkflowFreeTextTagsStep
                stepName="tagsFreeText"
                handleKeyWorkflowFreeTextTagInput={
                  handleKeyWorkflowFreeTextTagInput
                }
              ></KeyWorkflowFreeTextTagsStep>
              <ConfirmStep
                stepName="confirm"
                request={request}
                keyProviderRequestInformation={keyProviderRequestInformation}
                saveRequest={onSubmit}
              ></ConfirmStep>
            </StepWizard>
          ) : (
            <Loading></Loading>
          )}
        </div>
      </SlideoutLayout>
  ) : (
    <Loading></Loading>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.auth,
  };
}

const mapDispatchToProps = {
  saveRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestKeys);
