import {
  apiUrl,
  getList,
  get,
  postAction,
  deleteRecord,
  getAll,
  upload,
  putAction,
  deleteAction,
  download,
} from './apiUtils';
const baseUrl = apiUrl + '/skuPrices/';

export function getAllSKUPrices() {
  return getAll(baseUrl);
}
export function getAllDraftSKUPrices() {
  return getAll(`${baseUrl}draft`);
}

export function getSKUPrices(filters, _scopes, _sort, _order, _page, _limit) {
  return getList(
    `${baseUrl}search`,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function getDraftSKUPrices(
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit
) {
  return getList(
    `${baseUrl}draft/search`,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function getSKUPriceById(skuId, currencyId) {
  return get(`${baseUrl}?skuId=${skuId}&currencyId=${currencyId}`);
}

export function saveSKUPrice(skuId, currencyId, price) {
  return postAction(baseUrl, { skuId, currencyId, price });
}

export function deleteSKUPrices(skuId, currencyId) {
  return deleteRecord(baseUrl, `${skuId}/${currencyId}`);
}

export function deleteAllSKUPrices() {
  return deleteAction(`${baseUrl}all`);
}

export function uploadPrices(record) {
  return upload(`${baseUrl}upload`, record);
}

export function exportPrices(isDraft) {
  return download(`${baseUrl}export?isDraft=${isDraft}`);
}

export function publishDraft() {
  return putAction(`${baseUrl}draft/publish`, {});
}
