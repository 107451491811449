import React, { useState } from 'react';
import {
  loadPackageTypes,
  exportPackageTypeData,
} from '../../../../actions/packageTypeActions';
import { Link, NavLink } from 'react-router-dom';
import {
  Table,
  SimpleTextFilter,
  getTableSettingsDefaults,
} from '../../../common/Table';
import { CommaNumber_NoDecimal } from '../../../../util/formatter';
import Loading from '../../../common/Loading';
import KwPanel from '../../../common/KwPanel';
import { scopes as tableScopes } from '../../../../constants/scopes';
import useTableSettings from '../../../../hooks/useTableSettings';
import { useTranslation } from 'react-i18next';

function PackageTypeList({ location, reload }) {
  const { t } = useTranslation();
  const allColumns = ['name', 'gameCount'];
  const defaultVisibleColumns = allColumns;
  const [packageTypes, setPackageTypes] = useState();
  const params = new URLSearchParams(location.search);
  const { tableSettings, setTableSettings, filters, filterChange, isLoading } =
    useTableSettings(
      'packagetypelist-settings',
      getTableSettingsDefaults({ defaultSort: 'name', defaultVisibleColumns }),
      null,
      loadPackageTypes,
      setPackageTypes,
      reload,
      t('Failed to load package types'),
      location
    );

  // The base config of the columns, this will be filtered based on the visible (above)
  let columnsConfig = [
    {
      Key: 'name',
      Label: t('Name'),
      width: '40%',
      Render: (packageType) => (
        <Link to={'/settings/package-type/' + packageType.id}>
          {packageType.name}
        </Link>
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'gameCount',
      Label: t('Number of Games'),
      width: '30%',
      className: 'text-right num no-wrap',
      Render: (packageType) => (
        <NavLink to={`/games?${tableScopes.PACKAGETYPE}=${packageType.id}`}>
          {CommaNumber_NoDecimal(packageType.gameCount)}
        </NavLink>
      ),
    },
  ];

  // filter
  const visibleColumns = tableSettings.columns ?? defaultVisibleColumns;
  const columns = visibleColumns
    .map((x) => columnsConfig.find((y) => y.Key === x))
    .filter((x) => x);

  // return an alaska table
  return packageTypes ? (
    <KwPanel className="mb-4">
      <Table
        columns={columns}
        allColumns={allColumns}
        rows={packageTypes.data.map((x) => {
          return { Key: x.id, ...x };
        })}
        totalRowCount={packageTypes.totalRowCount}
        filteredRowCount={packageTypes.filteredRowCount}
        tableSettings={tableSettings}
        setTableSettings={setTableSettings}
        isLoading={isLoading}
        filters={filters}
        filterChange={filterChange}
        exportData={exportPackageTypeData}
      />
    </KwPanel>
  ) : (
    <Loading></Loading>
  );
}
export default PackageTypeList;
