import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import WorkflowsList from './components/WorkflowsList';
import ActionBar from '../../layout/ActionBar';
import Add from './slideout/Add';
import { getAllWorkflows } from '../../../actions/workflowActions';
import { scopes as tableScopes } from '../../../constants/scopes';
import SlidingPane from 'react-sliding-pane';
import Button from '../../common/Button';
import { useTranslation } from 'react-i18next';

function Workflows({ location, workflows, getAllWorkflows }) {
  const { t } = useTranslation();
  const [reload, setReload] = useState();
  const [showAdd, setShowAdd] = useState(false);
  const [addSlideoutOpen, setAddSlideoutOpen] = useState(false);

  useEffect(() => {
    if (!workflows) {
      getAllWorkflows();
    }
  }, [workflows, getAllWorkflows]);

  useEffect(() => {
    const getScopeFilters = (search) => {
      const params = new URLSearchParams(search);
      let scopes = {};
      for (const key of params.keys()) {
        if (key.startsWith('s_')) {
          scopes = {
            ...scopes,
            [key.substring(2)]: params.get(key),
          };
        }
      }
      return scopes;
    };
    let scopes = getScopeFilters(location.search);
    if (scopes && Object.keys(scopes).length > 0) {
      setShowAdd(false);
    } else {
      setShowAdd(true);
    }
  }, [location, location.search, setShowAdd]);

  return (
    <>
      <ActionBar>
        {showAdd && (
          <>
            <Link
              to={`/settings/workflows/?${tableScopes.ISARCHIVED}=true`}
              className="btn btn-default ms-2"
            >
              {t('Show deleted')}
            </Link>
            <Button
              className="btn btn-primary ms-2"
              onClick={() => setAddSlideoutOpen(true)}
              text={t('Add workflow')}
            />
          </>
        )}
      </ActionBar>
      <WorkflowsList location={location} reload={reload} />
      <SlidingPane
        isOpen={addSlideoutOpen}
        hideHeader={true}
        from="right"
        className="xlarge-side-panel"
        onRequestClose={() => setAddSlideoutOpen(false)}
      >
        <Add
          done={(d) => {
            setReload(new Date());
            setAddSlideoutOpen(false);
          }}
          cancel={() => {
            setAddSlideoutOpen(false);
          }}
        />
      </SlidingPane>
    </>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    location: ownProps.location,
    workflows: state.workflows,
  };
}

const mapDispatchToProps = {
  getAllWorkflows,
};

export default connect(mapStateToProps, mapDispatchToProps)(Workflows);
