import React from 'react';
import AutoSelectText from '../../../../common/AutoSelectText';
import NoPackageId from '../../../../common/NoPackageId';

function PackageIdCell({ packageId, editable }) {
  return (
    <div className="grid-game-name num text-center">
      {packageId ? (
        <AutoSelectText>{packageId}</AutoSelectText>
      ) : (
        <NoPackageId />
      )}
    </div>
  );
}

export default PackageIdCell;
