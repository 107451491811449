import React from 'react';
import PropTypes from 'prop-types';
import LeftNavWrapper from '../menu-components/LeftNavWrapper';
import LeftMenuItem from '../menu-components/LeftMenuItem';
import NavColours from '../../../constants/NavColours';
import { useTranslation } from 'react-i18next';
import icons from '../../../constants/icons';

function LeftMenu_Pricing({ currentUser, wrapperClassName }) {
  const { t } = useTranslation();

  return (
    <LeftNavWrapper className={wrapperClassName}>
      <LeftMenuItem
        url="/pricing/all"
        icon={icons.GRID_DIVIDER}
        colour={NavColours.NAV_COLOUR_1}
        title={t('All prices')}
        currentUser={currentUser}
      />
      <LeftMenuItem
        url="/pricing/upload"
        icon={icons.UPLOAD}
        colour={NavColours.NAV_COLOUR_1}
        title={t('Upload')}
        currentUser={currentUser}
      />
    </LeftNavWrapper>
  );
}

LeftMenu_Pricing.propTypes = {
  currentUser: PropTypes.object,
};

export default LeftMenu_Pricing;
