import { t } from 'i18next';
import React from 'react';

function PackageIdHeader({ filterAppPackageId, handleFilterAppPackageId }) {
  return (
    <div className="grid-header">
      <div className="no-border">{t('App Id / Package Id')}</div>
      <br />
      <input
        type="text"
        className="form-control"
        placeholder={t('Filter')}
        value={filterAppPackageId || ''}
        onChange={handleFilterAppPackageId}
      />
    </div>
  );
}

export default PackageIdHeader;
