import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import SlideoutLayout from '../../../layout/slideout/SlideoutLayout';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import UploaderForm from '../components/UploaderForm';
import { saveUploader } from '../../../../actions/uploaderActions';
import { getAllWorkflows } from '../../../../actions/workflowActions';
import { getKeyProviders } from '../../../../actions/keyProviderActions';
import { getPlatforms } from '../../../../actions/platformActions';
import { getUserProfileGroups } from '../../../../actions/userProfileGroupActions';
import { getAllCompanies } from '../../../../actions/companyActions';
import { getCompanyIdsForAnyPermission } from '../../../auth/authUtils';
import { useTranslation } from 'react-i18next';

function Add({
  done,
  id,
  cancel,
  workflows,
  getAllWorkflows,
  keyProviders,
  getKeyProviders,
  platforms,
  getPlatforms,
  keyProviderId,
  keyWorkflowId,
  platformId,
  companies,
  getAllCompanies,
  currentUser,
}) {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    getValues,
    clearErrors,
    setValue,
    watch,
  } = useForm();
  const addingSpecific = keyProviderId || keyWorkflowId || platformId;

  //companies
  useEffect(() => {
    if (!companies) {
      getAllCompanies().catch((e) => {
        toast.error(t('Failed to get companies'));
      });
    }
  }, [companies, getAllCompanies]);

  const [allowedPublishers, setAllowedPublishers] = useState();
  useEffect(() => {
    let pubs = getCompanyIdsForAnyPermission(currentUser);
    setAllowedPublishers(pubs);
  }, [companies, currentUser]);

  useEffect(() => {
    if (!workflows && !addingSpecific) {
      getAllWorkflows();
    }
  }, [workflows, getAllWorkflows, addingSpecific]);

  useEffect(() => {
    if (!keyProviders && !addingSpecific) {
      getKeyProviders();
    }
  }, [keyProviders, getKeyProviders, addingSpecific]);

  useEffect(() => {
    if (!platforms && !addingSpecific) {
      getPlatforms();
    }
  }, [platforms, getPlatforms, addingSpecific]);

  const [groups, setGroups] = useState();
  useEffect(() => {
    // get the groups
    if (!groups) {
      getUserProfileGroups()
        .then((d) => {
          setGroups(d.data);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [groups]);

  const onSubmit = (data) => {
    data = {
      ...data,
      ...{
        platformId: data.platformId || platformId,
        keyProviderId: data.keyProviderId || keyProviderId,
        keyWorkflowId: data.keyWorkflowId || keyWorkflowId,
      },
    };
    saveUploader(data)
      .then((savedUploader) => {
        toast.success(t('Uploader saved'));
        done(savedUploader);
      })
      .catch(() => {
        toast.error(t('Failed to save uploader'));
      });
  };

  const [uploader] = useState({ keyWorkflowId, keyProviderId, platformId });

  return (
    <SlideoutLayout
      title={id ? t('Edit uploader') : t('Add uploader')}
      cancel={cancel}
      done={done}
      bar={
        <input
          type="submit"
          value={t('Save')}
          className="btn btn-primary"
          onClick={handleSubmit(onSubmit)}
        />
      }
    >
      <UploaderForm
        uploader={uploader}
        register={register}
        errors={errors}
        control={control}
        getValues={getValues}
        setValue={setValue}
        watch={watch}
        workflows={workflows}
        clearErrors={clearErrors}
        keyProviders={keyProviders}
        platforms={platforms}
        addingSpecific={addingSpecific}
        groups={groups}
        companies={companies}
        allowedPublishers={allowedPublishers}
      ></UploaderForm>
    </SlideoutLayout>
  );
}
Add.propTypes = {
  id: PropTypes.string,
  cancel: PropTypes.func.isRequired,
  done: PropTypes.func.isRequired,
  keyProviderId: PropTypes.string,
  keyWorkflowId: PropTypes.string,
  platformId: PropTypes.string,
};

function mapStateToProps(state, ownProps) {
  return {
    workflows: state.workflows,
    keyProviders: state.keyProviders,
    platforms: state.platforms,
    company: state.company,
    companies: state.companies,
    currentUser: state.auth,
  };
}

const mapDispatchToProps = {
  getAllWorkflows,
  getKeyProviders,
  getPlatforms,
  getAllCompanies,
};

export default connect(mapStateToProps, mapDispatchToProps)(Add);
