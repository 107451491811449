import actionTypes from '../constants/actionTypes';
const initialState = { percentage: -1, message: '', updates: [] };
export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.GAMESKU_PRICING_UPLOAD_PROCESSING:
      return {
        ...state,
        percentage: action.progress.percentage,
        updates: [...state.updates, action.progress],
      };
    case actionTypes.GAMESKU_PRICING_UPLOAD_CLEAR:
      return initialState;
    default:
      return state;
  }
}
