import { apiUrl, getList, getSingleRecord, getAll } from './apiUtils';
const baseUrl = apiUrl + '/currencies/';

export function getCurrencies(filters, _scopes, _sort, _order, _page, _limit) {
  return getList(
    `${baseUrl}search`,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function getCurrencyById(id) {
  return getSingleRecord(baseUrl, id);
}

export function getAllCurrencies() {
  return getAll(baseUrl);
}
